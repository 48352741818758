import { Button } from '../shadcn/Button';
import { Dialog } from './Dialog';
import Input from './Input';
import Notice from './Notice';
import Select from './Select';
import { useState } from 'react';

interface Field {
    type: 'input' | 'select';
    name: string;
    label: string;
    placeholder?: string;
    options?: { label: string; value: string | boolean }[];
    defaultValue?: string | boolean;
}

interface ConfirmInputDialogProps {
    title: string;
    fields: Field[];
    onConfirm: (values: Record<string, string | boolean>) => Promise<true | string>;
    onClose: () => void;
}

export function ConfirmInputDialog({ title, fields, onConfirm, onClose }: ConfirmInputDialogProps) {
    const [values, setValues] = useState<Record<string, string | boolean>>(() => {
        const defaults: Record<string, string | boolean> = {};
        fields.forEach((field) => {
            if (field.defaultValue !== undefined) {
                defaults[field.name] = field.defaultValue;
            }
        });
        return defaults;
    });
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        setLoading(true);
        setError(null);

        try {
            const result = await onConfirm(values);
            if (result === true) {
                onClose?.();
            } else {
                setError(result);
            }
        } catch (err) {
            setError('Ha ocurrido un error inesperado');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog title={title} onClose={onClose} closable={false} >
            <form className="flex flex-col gap-4 pt-1" onSubmit={(e) => {
                e.preventDefault();
                handleConfirm();
            }}>
                {fields.map((field) => (
                    <div key={field.name}>
                        {field.type === 'input' ? (
                            <Input
                                name={field.name}
                                placeholder={field.placeholder || ''}
                                floatingLabel={field.label}
                                value={field.defaultValue as string || ''}
                                onChange={(value) => 
                                    setValues((prev) => ({ ...prev, [field.name]: value }))
                                }
                            />
                        ) : (
                            <Select
                                options={field.options || []}
                                defaultValue={field.defaultValue}
                                floatingLabel={field.label}
                                onSelectedChange={(value) =>
                                    setValues((prev) => ({ ...prev, [field.name]: typeof value === 'boolean' ? value : (value || '') }))
                                }
                            />
                        )}
                    </div>
                ))}

                {error && (
                    <Notice message={error} variant="error" />
                )}

                <div className="flex justify-end gap-3 mt-2">
                    <Button
                        disabled={loading}
                        variant="secondary"
                        onClick={onClose}
                        className="w-fit"
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        loading={loading}
                        className="w-fit"
                    >
                        Confirmar
                    </Button>
                </div>
            </form>
        </Dialog>
    );
}

