import { Button } from '../shadcn/Button';
import { Dialog } from './Dialog';
import Notice from './Notice';
import { useState } from 'react';

interface ConfirmDialogProps {
    title?: string;
    message?: string;
    onConfirm: () => Promise<true | string>;
    onClose: () => void;
}

export function ConfirmDialog({ title, message, onConfirm, onClose }: ConfirmDialogProps) {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        setLoading(true);
        setError(null);

        try {
            const result = await onConfirm();
            if (result === true) {
                onClose?.();
            } else {
                setError(result);
            }
        } catch (err) {
            console.error(err);
            setError('Ha ocurrido un error inesperado');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog title={title || ""} onClose={onClose} closable={false}>
            <form className="flex flex-col gap-4 pt-1" onSubmit={(e) => {
                e.preventDefault();
                handleConfirm();
            }}>
                {message && (
                    <p className="text-neutral-200">{message}</p>
                )}

                {error && (
                    <Notice message={error} variant="error" />
                )}

                <div className="flex justify-end gap-3 mt-2">
                    <Button
                        disabled={loading}
                        variant="secondary"
                        onClick={onClose}
                        className="w-fit"
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        loading={loading}
                        className="w-fit"
                    >
                        Confirmar
                    </Button>
                </div>
            </form>
        </Dialog>
    );
}

