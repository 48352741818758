import { useEffect, useState } from 'react';
import firestore from '../firestore';

export function GetBanks(country: string | null) {
	const [banks, setBanks] = useState<string[]>([]);

	useEffect(() => {
		if (!country) return;
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();

			if (document?.[country]?.banks?.length) {
				document[country].banks.sort((a: string, b: string) => a.localeCompare(b));
				setBanks(
					document?.[country]?.banks.filter((bank: string, index: number, self: string[]) => self.indexOf(bank) === index)
				);
			}
		})();
	}, [country]);

	return banks;
}

export function GetCities(country: string | null) {
	const [cities, setCities] = useState<string[]>([]);

	useEffect(() => {
		if (!country) return;
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
			if (document?.[country]?.cities?.length) {
				document[country].cities.sort((a: string, b: string) => a.localeCompare(b));
				setCities(
					document?.[country]?.cities.filter((city: string, index: number, self: string[]) => self.indexOf(city) === index)
				);
			}
		})();
	}, [country]);

	return cities;
}

export function GetDev2FANumbers(get_numbers: boolean = false) {
	const [dev2FANumbers, setDev2FANumbers] = useState<{ country: string; phone_number: string }[]>([]);

	useEffect(() => {
		if (!get_numbers) return;
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('AppSettings').get()).data();
			if (document?.dev_2fa_phone_numbers?.length) {
				setDev2FANumbers(document['dev_2fa_phone_numbers']);
			}
		})();
	}, [get_numbers]);

	return dev2FANumbers;
}

export function GetATACountryEmail(country: string | null) {
	const [ataEmail, setAtaEmail] = useState<string | null>(null);

	useEffect(() => {
		if (!country) return;
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
			if (document?.[country]?.ata_shared_email) {
				setAtaEmail(document[country].ata_shared_email);
			}
		})();
	}, [country]);

	return ataEmail;
}

export function GetHelpVideos(country: string | null) {
	const [helpVideos, setHelpVideos] = useState<
		{ title: string; subtitle?: string; description?: string; thumbnail?: string; url: string }[]
	>([]);

	useEffect(() => {
		if (!country) return;
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
			if (document?.[country]?.instructions?.length) {
				setHelpVideos(document[country].instructions);
			}
		})();
	}, [country]);

	return helpVideos;
}

export function GetTogglablePromotions(country: string | null, master_store_email: string | null) {
	type TogglablePromotions = {
		label: string;
		description: string;
		payment_options?: { installments: number; factor: number }[];
		initial_percentage?: number;
		approved_credit?: number; // can be Infinity
		imei_tacs?: string[];
	};

	const [togglablePromotions, setTogglablePromotions] = useState<TogglablePromotions[]>([]);

	useEffect(() => {
		if (!country || !master_store_email) return;

		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
			if (document?.[country]?.store_togglable_promotions) {
				const store_togglable_promotions = document[country].store_togglable_promotions as {
					[key: string]: TogglablePromotions[];
					global: TogglablePromotions[];
				};

				const specificPromos: TogglablePromotions[] = store_togglable_promotions?.[master_store_email] || [];
				const globalPromos: TogglablePromotions[] = store_togglable_promotions?.global || [];

				const mergedPromos = [
					...specificPromos,
					...globalPromos.filter(
						(globalPromo) =>
							!specificPromos.some((specificPromo) => specificPromo.label === globalPromo.label)
					)
				];

				setTogglablePromotions(mergedPromos);
			}
		})();
	}, [country, master_store_email]);

	return togglablePromotions;
}

export function GetPromotions(country: string | null, master_store_email: string | null) {
	type Promotions = {
		initial_percentage?: boolean | number;
		payment_options?: { installments: number; factor: number }[];
		approved_credit?: number; // can be Infinity
	}
	const [promotions, setPromotions] = useState<Promotions>({});

	useEffect(() => {
		if (!country || !master_store_email) return;

		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
			if (document?.[country]?.store_promotions) {
				const store_promotions = document[country].store_promotions as {
					[key: string]: Promotions;
					global: Promotions;
				};
				const initial_percentage = store_promotions?.[master_store_email]?.initial_percentage || store_promotions?.global?.initial_percentage;
				const payment_options = store_promotions?.[master_store_email]?.payment_options || store_promotions?.global?.payment_options;
				const approved_credit = store_promotions?.[master_store_email]?.approved_credit || store_promotions?.global?.approved_credit;

				setPromotions({
					...(initial_percentage && { initial_percentage }),
					...(payment_options && { payment_options }),
					...(approved_credit && { approved_credit })
				});
			}
		})();
		
	}, [country, master_store_email]);

	return promotions;
}

export function GetPermissionsBlacklist(master_email: string | null, user_email: string | null) {
	const [permissionsBlacklist, setPermissionsBlacklist] = useState<string[] | null>(null);

	useEffect(() => {
		if (!user_email) return;

		if (!master_email || master_email === user_email) {
			setPermissionsBlacklist([]);
			return;
		}

		(async () => {
			const document = (await firestore.collection('Stores').doc(master_email).get()).data();
			if (!document?.master_user || !document?.seller_permissions_blacklist) {
				setPermissionsBlacklist([]);
				return;
			}

			const blacklist = new Set<string>([
				...(document.seller_permissions_blacklist?.all_sellers || []),
				...(document.seller_permissions_blacklist?.[user_email] || [])
			]);
			setPermissionsBlacklist(Array.from(blacklist || []));
		})();
	}, [master_email, user_email]);

	return permissionsBlacklist;
}

