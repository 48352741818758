import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { ContainerSpinner } from "../components/Spinner";
import { Landmark } from "lucide-react";
import Notice from "../components/Notice";

export default function InformationBancaria() {
    const { userData } = useContext(GlobalContext);
    const bank = userData?.bank_account;
   
    if (!userData) {
        return <ContainerSpinner size={3} />
    }
    else if (!bank || !bank.bank_name || !bank.account_name || !bank.account_number || !bank.account_type) {
        return <div className="flex h-full items-center justify-center">
            <Notice variant="error" message="No hay información bancaria disponible" className="text-lg p-6" />
        </div>
    }
    
    return (
        <article className="group relative w-full shrink-0 overflow-hidden  p-6  md:p-8">
            <div className="absolute inset-0 -z-10 bg-gradient-to-br from-emerald-500/10 to-teal-500/10 opacity-0 transition-opacity group-hover:opacity-100" />

            <header className="mb-6 flex items-center justify-between">
                <h1 className="flex items-center gap-2 text-xl font-medium">
                    <Landmark className="size-5 text-emerald-400" />
                    Información Bancaria
                </h1>
            </header>

            <div className="space-y-4">
                <div className="rounded-lg bg-neutral-900/50 p-3">
                    <p className="text-sm text-neutral-400">Banco</p>
                    <p className="font-medium">{bank.bank_name}</p>
                </div>

                <div className="rounded-lg bg-neutral-900/50 p-3">
                    <p className="text-sm text-neutral-400">Titular</p>
                    <p className="font-medium">{bank.account_name}</p>
                </div>

                <div className="flex gap-4">
                    <div className="flex-1 rounded-lg bg-neutral-900/50 p-3">
                        <p className="text-sm text-neutral-400">Cuenta {bank.account_type}</p>
                        <p className="font-mono text-sm">{bank.account_number}</p>
                    </div>
                    {bank.document_type && (
                        <div className="flex-1 rounded-lg bg-neutral-900/50 p-3">
                            <p className="text-sm text-neutral-400">{bank.document_type?.toUpperCase()}</p>
                            <p className="font-mono">{bank.identification_number}</p>
                        </div>
                    )}
                </div>

                {bank.interbank_code && (
                    <div className="rounded-lg bg-neutral-900/50 p-3">
                        <p className="text-sm text-neutral-400">Código Interbancario</p>
                        <p className="font-mono text-sm">{bank.interbank_code}</p>
                    </div>
                )}
            </div>
        </article>
    );
}
