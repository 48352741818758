import * as Accordion from '@radix-ui/react-accordion';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import classNames from 'classnames';
import {
	BarChart3,
	Building2,
	ChevronDown,
	HelpCircle,
	Home,
	LayoutGrid,
	LogOut,
	LucideProps,
	Menu,
	MoonStar,
	NotepadText,
	Plus,
	Sheet,
	SlidersHorizontal,
	SunMedium,
	UserPen,
	UserSearch,
	UserSearchIcon,
	X
} from 'lucide-react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Link, Location, useLocation } from 'react-router-dom';
import Logo from '../components/Logo';
import { logout, UserData } from '../hooks/auth';
import { GetHelpVideos, GetPermissionsBlacklist } from '../hooks/firebase';
import clsx from 'clsx';

const getInitialTheme = (): 'dark' | 'light' => {
	const savedTheme = localStorage.getItem('theme');
	if (savedTheme === 'dark' || savedTheme === 'light') {
		return savedTheme;
	}
	// If no theme is saved, default to dark
	return 'dark';
};

const DesktopNavigation = ({
	isOpen,
	setOpen,
	location,
	theme,
	setTheme,
	userData,
	accessToOtherStores,
	helpVideos,
	internal,
	internalPath,
	permissionsBlacklist
}: {
	location: Location;
	isOpen: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	theme: 'dark' | 'light';
	setTheme: React.Dispatch<React.SetStateAction<'dark' | 'light'>>;
	userData: UserData;
	accessToOtherStores: string;
	helpVideos: ReturnType<typeof GetHelpVideos>;
	internal: boolean;
	internalPath: { get: () => string; set: (path: string) => void };
	permissionsBlacklist: ReturnType<typeof GetPermissionsBlacklist>;
}) => {
	const MenuCategory = ({
		label,
		children,
		Icon
	}: {
		label: string;
		Icon: React.ForwardRefExoticComponent<Omit<LucideProps, 'ref'>>;
		children: ReactNode;
	}) => (
		<Accordion.Item className="w-full select-none space-y-1.5" value={label}>
			<Accordion.Trigger className="group flex w-full cursor-pointer items-center justify-between text-[1.075rem] font-medium tracking-tight text-neutral-0">
				<div className="flex items-center justify-center gap-2 rounded-lg font-semibold transition-colors group-hover:text-primary-200 group-data-[state=open]:text-primary-200 light:group-hover:text-primary-600 light:group-data-[state=open]:text-primary-600">
					<Icon className="size-5" strokeWidth={2.25} />
					<span>{label}</span>
				</div>

				<ChevronDown
					className="size-4 text-primary-200 transition-transform group-data-[state=closed]:-rotate-90 light:text-primary-600"
					strokeWidth={3}
				/>
			</Accordion.Trigger>
			<Accordion.Content className="overflow-hidden data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown">
				<ul className="text-[0.9rem] text-neutral-200">{children}</ul>
			</Accordion.Content>
		</Accordion.Item>
	);

	const MenuItem = ({ label, path }: { label: string; path: string }) => (
		<li>
			<Link
				onClick={(e) => {
					if (internal) {
						e.preventDefault();
						internalPath.set(path);
					}
					setOpen(false);
				}}
				to={path}
				className={classNames(
					'block w-full rounded-lg px-2 py-1.5 leading-tight transition-colors hover:bg-primary-500/50 hover:text-neutral-0',
					{
						'bg-primary-500/50 py-1 font-semibold text-neutral-0': !internal
							? location.pathname === path
							: internalPath.get() === path || (label === 'Administrar clientes' && internalPath.get() === '')
					}
				)}
			>
				{label}
			</Link>
		</li>
	);

	const MenuCategories = !internal
		? ['Menu Principal', 'Ajustes', 'Tienda', 'Reportes', 'Administración', 'Ayuda']
		: ['Clientes', 'Gestión de Pagos', 'Administración', 'Tiendas'];

	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollAreaRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (scrollAreaRef.current) {
				setScrollPosition(scrollAreaRef.current.scrollTop);
			}
		};

		const currentScrollArea = scrollAreaRef.current;
		if (currentScrollArea) {
			currentScrollArea.scrollTop = scrollPosition;
			currentScrollArea.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (currentScrollArea) {
				currentScrollArea.removeEventListener('scroll', handleScroll);
			}
		};
	});

	return (
		<nav
			className={classNames(
				'ease-[cubic-bezier(0.87,0,0.13,1)] fixed right-0 z-30 flex h-svh w-72 shrink-0 translate-x-72 flex-col items-center justify-start bg-foreground transition-transform duration-0 light:border-r-[1.5px] light:border-neutral-800/80 xl:left-0 xl:translate-x-0',
				{
					'!translate-x-0 !duration-500': isOpen
				}
			)}
			onClick={(e) => e.stopPropagation()}
		>
			<div className="flex h-20 w-full items-center justify-between border-b-[1.5px] border-neutral-800/80 px-6 xl:justify-center xl:px-0">
				<Link to={'/'}>
					<Logo size="full" className="w-28" />
				</Link>
				<X
					strokeWidth={2.66}
					className="size-7 cursor-pointer text-primary-200 transition-opacity hover:opacity-70 light:text-primary-600 xl:hidden"
					onClick={(e) => {
						e.stopPropagation();
						setOpen(false);
					}}
				/>
			</div>

			<ScrollArea.Root className="h-full w-64 overflow-hidden">
				<ScrollArea.Viewport ref={scrollAreaRef} className="h-full w-full">
					<Accordion.Root type="multiple" defaultValue={MenuCategories} className="w-full space-y-8 p-6">
						{!internal && (
							<>
								<MenuCategory label="Menu Principal" Icon={LayoutGrid}>
									<MenuItem label="Inicio" path="/" />
									<MenuItem label="Nuevo registro" path="/newSale" />
									{!permissionsBlacklist?.includes('register_payment') && (
										<MenuItem label="Registrar pago" path="/buscarcliente" />
									)}
									<MenuItem label="Simulador" path="/simulator" />
								</MenuCategory>

								{userData.master_user && (
									<MenuCategory label="Ajustes" Icon={UserPen}>
										<MenuItem label="Mis tiendas" path="/sucursales" />
										{userData.country === 'venezuela' && userData.self_financed && !userData.financed_by_krece && (
											<MenuItem label="Métodos de pago" path="/metodos-de-pago" />
										)}
									</MenuCategory>
								)}

								<MenuCategory label="Reportes" Icon={BarChart3}>
									{(!userData.self_financed && userData.financed_by_krece) || !userData.master_user ? null : (
										<>
											<MenuItem label="Cuentas por cobrar" path="/cpc" />
											<MenuItem label="Cuotas" path="/reportes/cuotas" />
										</>
									)}
									<MenuItem label="Transacciones" path="/reportes/transacciones" />
									<MenuItem label="Ventas" path="/reportes/ventas" />
									<MenuItem label="Registros eliminados" path="/reportes/registros-eliminados" />
									{userData.master_user &&
										((userData.self_financed && !userData.financed_by_krece) || userData.country !== 'venezuela') && (
											<MenuItem label="Clientes en mora" path="/reportes/delincuentes" />
										)}
								</MenuCategory>

								<MenuCategory label="Administración" Icon={SlidersHorizontal}>
									{!permissionsBlacklist?.includes('delete_sale') && <MenuItem label="Eliminar venta" path="/deleteSale" />}
									{!permissionsBlacklist?.includes('delete_payment') && <MenuItem label="Eliminar pago" path="/deletePayment" />}
									{!permissionsBlacklist?.includes('change_sale_price') && (
										<MenuItem label="Cambiar precio de venta" path="/changePrice" />
									)}
									<MenuItem label="Cambio por garantía" path="/warranty" />
									<MenuItem label="Cambiar correo" path="/changeEmail" />
									{accessToOtherStores && <MenuItem label="Logear como" path="/log-in-as" />}
									{!userData.self_financed && userData.financed_by_krece && userData.bank_account && userData.master_user && (
											<MenuItem label="Información bancaria" path="/informacion-bancaria" />
										)}
								</MenuCategory>

								{helpVideos.length > 0 && (
									<MenuCategory label="Ayuda" Icon={HelpCircle}>
										<MenuItem label="Videos instructivos" path="/ayuda" />
									</MenuCategory>
								)}
							</>
						)}
						{internal && (
							<>
								<MenuCategory label="Clientes" Icon={UserSearchIcon}>
									<MenuItem label="Administrar clientes" path="/buscar-clientes" />
								</MenuCategory>

								<MenuCategory label="Gestión de Pagos" Icon={NotepadText}>
									<MenuItem label="Registrar pago" path="/registrar-pago" />
									<MenuItem label="Pagos por verificar" path="/pagos-por-verificar" />
									<MenuItem label="Buscar pagos de tienda" path="/buscar-pagos-de-tienda" />
									<MenuItem label="Buscar pago en banco" path="/buscar-pago-en-banco" />
									<MenuItem label="Registrar pago reembolsado" path="/registrar-pago-reembolsado" />
								</MenuCategory>

								{userData?.access_type === 'ata' && (
									<>
										<MenuCategory label="Administración" Icon={LayoutGrid}>
											<MenuItem label="Onboarding" path="/onboarding" />
											<MenuItem label="KPIs" path="/kpis" />
											<MenuItem label="Cambiar fecha a 15 y último" path="/cambiar-fecha-a-15-y-ultimo" />
										</MenuCategory>

										<MenuCategory label="Tiendas" Icon={Building2}>
											<MenuItem label="Subir límite de cupos" path="/subir-limite-de-cupos" />
										</MenuCategory>
									</>
								)}
							</>
						)}
					</Accordion.Root>
				</ScrollArea.Viewport>
				<ScrollArea.Scrollbar
					className="duration-[160ms] flex touch-none select-none p-0.5 ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
					orientation="vertical"
				>
					<ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
				</ScrollArea.Scrollbar>
			</ScrollArea.Root>

			<div className="flex h-20 w-full items-center justify-between border-t-[1.5px] border-neutral-800/80 px-4">
				<div className="flex items-center justify-center gap-1.5">
					<button
						onClick={(e) => {
							e.stopPropagation();
							setTheme(theme === 'dark' ? 'light' : 'dark');
						}}
					>
						<MoonStar
							className={classNames('size-7 cursor-pointer text-neutral-0', { hidden: theme === 'light' })}
							strokeWidth={1.75}
						/>
						<SunMedium
							className={classNames('size-7 cursor-pointer text-neutral-0', { hidden: theme === 'dark' })}
							strokeWidth={1.75}
						/>
					</button>
				</div>
				<div className="flex items-center gap-2">
					{helpVideos.length > 0 && (
						<Link
							to={'/ayuda'}
							className="flex items-center justify-center gap-1 rounded-lg border border-neutral-700 bg-neutral-900 p-1.5 text-sm text-neutral-200 transition-opacity hover:opacity-75 light:bg-neutral-925"
						>
							Ayuda
							<HelpCircle className="size-4" strokeWidth={2.5} />
						</Link>
					)}
					<button
						onClick={(e) => {
							e.stopPropagation();
							logout();
						}}
						className="flex items-center justify-center gap-1 rounded-lg border border-neutral-700 bg-neutral-900 p-1.5 text-sm text-neutral-200 transition-opacity hover:opacity-75 light:bg-neutral-925"
					>
						Salir
						<LogOut className="size-4" strokeWidth={2.5} />
					</button>
				</div>
			</div>
		</nav>
	);
};

const TabletNavigation = ({
	setOpen,
	location,
	theme,
	setTheme,
	permissionsBlacklist
}: {
	location: Location;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	theme: 'dark' | 'light';
	setTheme: React.Dispatch<React.SetStateAction<'dark' | 'light'>>;
	permissionsBlacklist: ReturnType<typeof GetPermissionsBlacklist>;
}) => {
	const MenuItem = ({
		label,
		path,
		Icon
	}: {
		label: string;
		path: string;
		Icon: React.ForwardRefExoticComponent<Omit<LucideProps, 'ref'>>;
	}) => (
		<li>
			<Link
				to={path}
				className={classNames(
					'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200 light:hover:text-primary-600',
					{
						'!text-primary-200 light:!text-primary-600': location.pathname === path
					}
				)}
			>
				<div
					className={classNames(
						'flex size-8 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100 light:group-hover:bg-primary-500/40',
						{
							'!bg-primary-500/50 !text-neutral-0 light:!bg-primary-500/40 light:!text-primary-800': location.pathname === path
						}
					)}
				>
					<Icon className="size-6" />
				</div>
				{label}
			</Link>
		</li>
	);

	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollAreaRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		const handleScroll = () => {
			if (scrollAreaRef.current) {
				setScrollPosition(scrollAreaRef.current.scrollTop);
			}
		};

		const currentScrollArea = scrollAreaRef.current;
		if (currentScrollArea) {
			currentScrollArea.scrollTop = scrollPosition;
			currentScrollArea.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (currentScrollArea) {
				currentScrollArea.removeEventListener('scroll', handleScroll);
			}
		};
	});

	return (
		<nav className="fixed left-0 hidden h-svh w-16 shrink-0 flex-col items-center justify-between gap-8 bg-foreground light:border-r-[1.5px] light:border-neutral-800/80 md:flex xl:hidden">
			<Link to={'/'} className="flex h-20 w-full shrink-0 items-center justify-center border-b-[1.5px] border-neutral-800/80">
				<Logo size="min" className="size-9" />
			</Link>
			<ul className="flex shrink-0 flex-col gap-2">
				<li className={clsx(location.pathname === '/internal' && 'hidden')}>
					<Link
						to={'/'}
						className={classNames(
							'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 hover:bg-primary-500/50 hover:text-neutral-0 light:hover:bg-primary-500/40',
							{
								'!bg-primary-500/50 !text-neutral-0 light:!bg-primary-500/40 light:!text-primary-800': location.pathname === '/'
							}
						)}
					>
						<Home className="size-6" />
					</Link>
				</li>
				<li
					className="flex size-9 cursor-pointer items-center justify-center rounded-lg text-neutral-100 transition-colors hover:text-primary-200 light:hover:text-primary-600"
					onClick={(e) => {
						e.stopPropagation();
						setOpen(true);
					}}
				>
					<LayoutGrid className="size-6" />
				</li>
			</ul>

			<ScrollArea.Root className="h-full w-full overflow-hidden">
				<ScrollArea.Viewport ref={scrollAreaRef} className="h-full w-full">
					<ul
						className={clsx(
							'flex shrink-0 flex-col gap-5 text-[0.7rem] font-semibold tracking-tight',
							location.pathname === '/internal' && 'hidden'
						)}
					>
						<MenuItem label="Registro" path="/newSale" Icon={Plus} />
						<MenuItem label="Ventas" path="/reportes/ventas" Icon={Sheet} />
						{!permissionsBlacklist?.includes('register_payment') && (
							<MenuItem label="Clientes" path="/buscarcliente" Icon={UserSearch} />
						)}
					</ul>
				</ScrollArea.Viewport>
				<ScrollArea.Scrollbar
					className="duration-[160ms] flex touch-none select-none p-0.5 ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
					orientation="vertical"
				>
					<ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
				</ScrollArea.Scrollbar>
			</ScrollArea.Root>

			<div className="flex w-full shrink-0 flex-col items-center justify-center gap-4 border-t-[1.5px] border-neutral-800/80 py-4">
				<button
					className={classNames('flex size-9 shrink-0 items-center justify-center')}
					onClick={(e) => {
						e.stopPropagation();
						setTheme(theme === 'dark' ? 'light' : 'dark');
					}}
				>
					<MoonStar
						className={classNames('size-6 shrink-0 cursor-pointer text-neutral-0', { hidden: theme === 'light' })}
						strokeWidth={1.75}
					/>
					<SunMedium
						className={classNames('size-6 shrink-0 cursor-pointer text-neutral-0', { hidden: theme === 'dark' })}
						strokeWidth={1.75}
					/>
				</button>

				<button
					onClick={(e) => {
						e.stopPropagation();
						logout();
					}}
					className="flex size-9 items-center justify-center gap-2 rounded-lg bg-neutral-900 transition-colors hover:bg-neutral-700"
				>
					<LogOut className="size-7 text-neutral-300" />
				</button>
			</div>
		</nav>
	);
};

const MobileNavigation = ({
	setOpen,
	location,
	theme,
	setTheme,
	permissionsBlacklist
}: {
	location: Location;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	theme: 'dark' | 'light';
	setTheme: React.Dispatch<React.SetStateAction<'dark' | 'light'>>;
	permissionsBlacklist: ReturnType<typeof GetPermissionsBlacklist>;
}) => {
	return (
		<nav className="fixed bottom-0 flex h-[4.5rem] w-full shrink-0 items-center justify-between border-t-[1.5px] border-neutral-800/80 bg-foreground px-4 py-2 md:hidden md:border-t-0">
			<ul className="flex gap-6 text-[0.7rem] text-xs font-semibold tracking-tight min-[400px]:gap-8">
				<li className={clsx(location.pathname === '/internal' && 'hidden')}>
					<Link
						to={'/'}
						className={classNames(
							'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200 light:hover:text-primary-600',
							{
								'!text-primary-200 light:!text-primary-600': location.pathname === '/'
							}
						)}
					>
						<div
							className={classNames(
								'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100 light:group-hover:bg-primary-500/40',
								{
									'!bg-primary-500/50 !text-neutral-0 light:!bg-primary-500/40': location.pathname === '/'
								}
							)}
						>
							<Logo
								size="min"
								className="size-6"
								fill={
									location.pathname === '/'
										? 'light:fill-primary-800 fill-neutral-100'
										: 'fill-neutral-100 light:fill-neutral-200'
								}
							/>
						</div>
						Inicio
					</Link>
				</li>
				<li className={clsx(location.pathname === '/internal' && 'hidden')}>
					<Link
						to={'/newSale'}
						className={classNames(
							'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200 light:hover:text-primary-600',
							{
								'!text-primary-200 light:!text-primary-600': location.pathname === '/newSale'
							}
						)}
					>
						<div
							className={classNames(
								'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100 light:group-hover:bg-primary-500/40',
								{
									'!bg-primary-500/50 !text-neutral-0 light:!bg-primary-500/40 light:!text-primary-800':
										location.pathname === '/newSale'
								}
							)}
						>
							<Plus className="size-6 scale-110" strokeWidth={2.5} />
						</div>
						Registro
					</Link>
				</li>
				<li className={clsx(location.pathname === '/internal' && 'hidden')}>
					<Link
						to={'/reportes/ventas'}
						className={classNames(
							'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200 light:hover:text-primary-600',
							{
								'!text-primary-200 light:!text-primary-600': location.pathname === '/reportes/ventas'
							}
						)}
					>
						<div
							className={classNames(
								'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100 light:group-hover:bg-primary-500/40',
								{
									'!bg-primary-500/50 !text-neutral-0 light:!bg-primary-500/40 light:!text-primary-800':
										location.pathname === '/reportes/ventas'
								}
							)}
						>
							<Sheet className="size-6" />
						</div>
						Ventas
					</Link>
				</li>
				{!permissionsBlacklist?.includes('register_payment') && (
					<li className={clsx(location.pathname === '/internal' && 'hidden')}>
						<Link
							to={'/buscarcliente'}
							className={classNames(
								'group flex flex-col items-center text-neutral-100 transition-colors duration-300 hover:text-primary-200 light:hover:text-primary-600',
								{
									'!text-primary-200 light:!text-primary-600': location.pathname === '/buscarcliente'
								}
							)}
						>
							<div
								className={classNames(
									'flex size-9 items-center justify-center rounded-lg text-neutral-100 transition-colors duration-300 group-hover:bg-primary-500/50 group-hover:text-neutral-100 light:group-hover:bg-primary-500/40',
									{
										'!bg-primary-500/50 !text-neutral-0 light:!bg-primary-500/40 light:!text-primary-800':
											location.pathname === '/buscarcliente'
									}
								)}
							>
								<UserSearch className="size-6" strokeWidth={2.25} />
							</div>
							Clientes
						</Link>
					</li>
				)}
			</ul>

			<div className="flex items-center justify-center gap-4">
				<button
					onClick={(e) => {
						e.stopPropagation();
						setTheme(theme === 'dark' ? 'light' : 'dark');
					}}
				>
					<MoonStar
						className={classNames('size-7 cursor-pointer text-neutral-0', { hidden: theme === 'light' })}
						strokeWidth={1.75}
					/>
					<SunMedium
						className={classNames('size-7 cursor-pointer text-neutral-0', { hidden: theme === 'dark' })}
						strokeWidth={1.75}
					/>
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						setOpen(true);
					}}
				>
					<Menu className="size-9 text-neutral-0 transition-colors hover:text-primary-200" />
				</button>
			</div>
		</nav>
	);
};

const Overlay = () => (
	<div className={classNames('fixed z-20 size-full animate-[fadeOverlayIn_350ms_ease-in_forwards] bg-black opacity-0')}></div>
);

export default function Navigation({
	userData,
	accessToOtherStores,
	internal,
	internalPath,
	permissionsBlacklist
}: {
	userData: UserData;
	accessToOtherStores: string;
	internal: boolean;
	internalPath: { get: () => string; set: (path: string) => void };
	permissionsBlacklist: ReturnType<typeof GetPermissionsBlacklist>;
}) {
	const location = useLocation();

	const [theme, setTheme] = useState<'dark' | 'light'>(getInitialTheme);
	const [isOpen, setOpen] = useState(false);

	// Hide menu on outside click
	useEffect(() => {
		const page = document.getElementById('root')?.firstElementChild;
		if (!page) return;

		const handleClick = () => setOpen(false);
		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	});

	// Update theme in localStorage and DOM
	useEffect(() => {
		localStorage.setItem('theme', theme);
		document.documentElement.classList.remove('dark', 'light');
		document.documentElement.classList.add(theme);
	}, [theme]);

	const helpVideos = GetHelpVideos(userData.country);

	return (
		<>
			<DesktopNavigation
				userData={userData}
				isOpen={isOpen}
				setOpen={setOpen}
				location={location}
				theme={theme}
				setTheme={setTheme}
				accessToOtherStores={accessToOtherStores}
				helpVideos={helpVideos}
				internal={internal}
				internalPath={internalPath}
				permissionsBlacklist={permissionsBlacklist}
			/>
			<TabletNavigation
				setOpen={setOpen}
				location={location}
				theme={theme}
				setTheme={setTheme}
				permissionsBlacklist={permissionsBlacklist}
			/>
			<MobileNavigation
				setOpen={setOpen}
				location={location}
				theme={theme}
				setTheme={setTheme}
				permissionsBlacklist={permissionsBlacklist}
			/>

			{isOpen && <Overlay />}
		</>
	);
}
