import React, { useState, useEffect, useContext } from 'react';
import { MenuItem } from '@material-ui/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import auth from '../auth';
import { ContainerSpinner } from '../components/Spinner';
import Select from '../components/Select';
import Input from '../components/Input';
import { GlobalContext } from '../context/GlobalContext';
import { GetPromotions } from '../hooks/firebase';

// Initialize Firebase
firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FB_APP_ID,
	measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

const Simulator = () => {
	const { userData } = useContext(GlobalContext);
	const navigate = useNavigate();

	const store_promotions = GetPromotions(userData?.country, userData?.master_store?.id);
	const initial_percentage_promotion = store_promotions?.initial_percentage;
	const payment_options_promotion = store_promotions?.payment_options;

	const [applicationApprovalData, setApplicationApprovalData] = useState({
		threeMonthAmount: 150,
		sixMonthAmount: ''
	});
	const [downPaymentPerc, setDownPaymentPerc] = useState(null);

	const [level, setLevel] = useState('level_1');
	const [timeToPay, setTimeToPay] = useState(3);
	const [interestFactor, setInterestFactor] = useState(0.1);
	const [isLoading, setIsLoading] = useState(true);
	const [amountSlider, setAmountSlider] = useState(50);
	const [countrySettings, setCountrySettings] = useState('');

	var currentDay = new Date().getDate();
	if (currentDay === 31) {
		currentDay = 30;
	}

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in, see docs for a list of available properties
				const storeRef = firestore.collection('Stores').doc(user.email);
				storeRef.get().then((doc) => {
					const data = doc.data();
					if (data.cobranza_access) {
						navigate('/internal');
						setIsLoading(false);
						return;
					}
					const masterStoreRef = data.master_store;
					// const storeRef = firestore.collection('Stores').doc(masterStore);
					masterStoreRef.get().then((doc) => {
						const data = doc.data();
						if (data.country === 'rd') {
							setApplicationApprovalData({ threeMonthAmount: 12000 });
						} else if (data.country === 'peru') {
							setApplicationApprovalData({ threeMonthAmount: 800 });
						}
						const ref = firestore.collection('AppSetting').doc('CountrySettings');
						ref.get().then((doc) => {
							try {
								const dat = doc.data();
								setCountrySettings(dat[data.country]);
								setInterestFactor(dat[data.country].payment_options.level_1[0].factor);
								setTimeToPay(dat[data.country].payment_options.level_1[0].installments / 2);
								setDownPaymentPerc(dat[data.country].down_payment.level_1);
							} catch (err) {
								setIsLoading(false);
							} finally {
								setIsLoading(false);
							}
						});
					});
				});
			} else {
				navigate('/login');
				console.log('user is logged out');
				setIsLoading(false);
			}
		});
	}, []);

	const getFactorByInstallment = (installment, data) => {
		for (const level in data) {
			for (const entry of data[level]) {
				if (entry.installments === installment) {
					return entry.factor;
				}
			}
		}
		return null; // return null if the installment is not found
	};

	function changeTimeToPay(ttp) {
		setTimeToPay(ttp);
		const factor = getFactorByInstallment(ttp * 2, countrySettings.payment_options);
		setInterestFactor(factor);
	}

	const extractLevelNumber = (levelString) => {
		const match = levelString.match(/level_(\d+)/);
		return match ? parseInt(match[1], 10) : null;
	};

	const renderPaymentOptions = (userLevel, data) => {
		const levelNum = extractLevelNumber(userLevel);
		let componentsToRender = [];

		for (let level = 1; level <= levelNum; level++) {
			const levelKey = `level_${level}`;
			if (data[levelKey]) {
				for (const option of payment_options_promotion || []) {
					const current_option = data[levelKey].find((item) => item.installments === option.installments);
					if (current_option && current_option.factor > option.factor) {
						data[levelKey].splice(data[levelKey].indexOf(current_option), 1);
						data[levelKey].push(option);
					} else if (!current_option) {
						data[levelKey].push(option);
					}
				}

				data[levelKey].forEach((item) => {
					componentsToRender.push(
						<MenuItem key={`${levelKey}-${item.installments}`} value={item.installments / 2}>
							{item.installments}
						</MenuItem>
					);
				});
			}
		}

		return componentsToRender;
	};

	function renderDownPayments(start) {
		const result = [];
		for (let i = start; i <= 50; i += 5) {
			result.push(i);
		}
		if (initial_percentage_promotion && !result.includes(initial_percentage_promotion * 100)) {
			result.unshift(initial_percentage_promotion * 100);
		}
		return result;
	}

	if (isLoading) return <ContainerSpinner size={3} />;

	return (
		<div className="flex min-h-full min-w-full flex-col items-center justify-center p-4 md:p-6">
			<div className="w-full max-w-5xl space-y-8 duration-500 animate-in fade-in">
				<div className="w-full rounded-2xl border border-neutral-700/50 bg-neutral-900/30 p-6 shadow-xl backdrop-blur-md duration-700 animate-in fade-in slide-in-from-bottom-4 light:bg-neutral-950/80 light:shadow-none md:p-8">
					<div className="space-y-8">
						<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
							<Select
								title="Nivel"
								options={[
									{ label: 'Azul', value: 'level_1' },
									{ label: 'Plata', value: 'level_2' },
									{ label: 'Oro', value: 'level_3' },
									{ label: 'Platino', value: 'level_4' }
								]}
								defaultValue={level}
								onSelectedChange={(value) => {
									setLevel(value);
									setDownPaymentPerc(countrySettings.down_payment[value]);
								}}
								className="w-full"
							/>

							<div className="space-y-1">
								<label className="block font-medium tracking-tight text-neutral-300">Línea de compra</label>
								<Input
									placeholder="0.00"
									allowedChars="numeric"
									value={applicationApprovalData.threeMonthAmount?.toString()}
									onChange={(value) => setApplicationApprovalData({ threeMonthAmount: value ? Number.parseFloat(value) : 0 })}
									Icon={
										countrySettings?.currency_code ? (
											<span className="text-sm text-neutral-500">{countrySettings.currency_code}</span>
										) : undefined
									}
								/>
							</div>

							<Select
								title="Inicial deseada"
								options={renderDownPayments(countrySettings.down_payment[level] * 100).map((item) => ({
									label: item + '%',
									value: item / 100
								}))}
								defaultValue={downPaymentPerc?.toString()}
								onSelectedChange={(value) => setDownPaymentPerc(value ? Number.parseFloat(value) : 0)}
								className="w-full"
							/>

							<Select
								title="Número de cuotas"
								options={renderPaymentOptions(level, countrySettings.payment_options).map((item) => ({
									label: item.props.children,
									value: item.props.children / 2
								}))}
								defaultValue={timeToPay?.toString()}
								onSelectedChange={(value) => changeTimeToPay(Number.parseInt(value))}
								className="w-full"
							/>
						</div>

						<div className="grid grid-cols-1 gap-6 pt-4 md:grid-cols-3">
							<div className="rounded-xl border border-neutral-800 bg-background p-6 transition-all duration-300 hover:border-primary-500/50 light:bg-neutral-1000">
								<span className="mb-3 block text-sm font-medium text-neutral-400">Precio del equipo</span>
								<div className="relative">
									<Input
										className="!bg-transparent !shadow-none"
										placeholder="0.00"
										allowedChars="numeric"
										value={amountSlider?.toString()}
										onChange={(value) => setAmountSlider(value ? Number.parseFloat(value) : 0)}
										validation={(value) => (!value || !Number.parseFloat(value) ? false : true)}
										inputClassName="border-b-[1.5px] border-neutral-800 !text-3xl md:!text-4xl !font-bold !text-primary-500"
										Icon={
											countrySettings?.currency_code ? (
												<span className="!text-3xl !font-bold !text-primary-500 md:!text-4xl">
													{countrySettings.currency_code}
												</span>
											) : undefined
										}
									/>
								</div>
							</div>

							<div className="rounded-xl border border-neutral-800 bg-background p-6 transition-all duration-300 hover:border-primary-500/50 light:bg-neutral-1000">
								<span className="mb-3 block text-sm font-medium text-neutral-400">
									{`Cuota inicial (${(!amountSlider
										? 0
										: ((applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc) >= amountSlider
												? amountSlider * downPaymentPerc
												: (applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc)) * downPaymentPerc +
													(amountSlider - applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc))) *
												100) /
											amountSlider
									).toFixed(0)}%)`}
								</span>
								<p className="bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-3xl font-bold text-transparent md:text-4xl">
									{countrySettings.currency_code}

									{(applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc) >= amountSlider
										? amountSlider * downPaymentPerc
										: (
												(applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc)) * downPaymentPerc +
												(amountSlider - applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc))
											)
									).toFixed(0)}
								</p>
							</div>

							<div className="rounded-xl border border-neutral-800 bg-background p-6 transition-all duration-300 hover:border-primary-500/50 light:bg-neutral-1000">
								<span className="mb-3 block text-sm font-medium text-neutral-400">{timeToPay * 2} cuotas quincenales de</span>
								<p className="bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-3xl font-bold text-transparent md:text-4xl">
									{countrySettings.currency_code}
									{Math.round(
										((applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc) >= amountSlider
											? amountSlider * (1 - downPaymentPerc)
											: (applicationApprovalData.threeMonthAmount / (1 - downPaymentPerc)) * (1 - downPaymentPerc)) *
											interestFactor) /
											(parseInt(timeToPay) * 2)
									).toFixed(0)}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Simulator;
