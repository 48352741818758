import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from './utils';
import Spinner from '../components/Spinner';

const buttonVariants = cva(
	'relative inline-flex select-none items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-30',
	{
		variants: {
			variant: {
				default: 'h-10 bg-neutral-950 px-4 text-sm font-medium outline-none transition-colors hover:bg-primary-600',
				destructive: 'w-full bg-red-500 hover:bg-red-600 text-white font-medium py-3 px-4 rounded-lg transition-colors',
				outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
				ghost: 'hover:bg-accent hover:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
				primary: 'w-full light:bg-primary-600 light:hover:bg-primary-700 bg-primary-500 hover:bg-primary-600 text-black font-medium py-3 px-4 rounded-lg transition-colors text-primary-50 hover:text-white',
				secondary: 'w-full bg-neutral-800 hover:bg-neutral-900 text-neutral-300 font-medium py-3 px-4 rounded-lg transition-colors',
				text: 'w-full bg-transparent hover:bg-transparent text-neutral-300 font-medium py-3 px-4 rounded-lg transition-colors'
			},
			size: {
				default: 'h-10 px-4 py-2',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				icon: 'h-10 w-10'
			}
		},
		defaultVariants: {
			variant: 'default',
			size: 'default'
		}
	}
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	loading?: boolean;
	Icon?: React.ReactNode;
	containerClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, loading = false, Icon, children, containerClassName = '', ...props }, ref) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				type={props?.type || "button"}
				{...props}
				disabled={(loading || props.disabled) ? true : false}
			>
				<span className={cn(
					"inline-flex items-center gap-2",
					loading && "invisible",
					containerClassName
				)}>
					{Icon}
					{children}
				</span>
				{loading && (
					<span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 [&>*]:!border-t-neutral-300 flex items-center justify-center">
						<Spinner size={1.25} />
					</span>
				)}
			</Comp>
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
