import React, { ReactNode, useContext } from 'react';
import Navigation from '../components/Navigation';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from './Spinner';
import { Navigate, useLocation } from 'react-router-dom';
import Onboarding from './Onboarding';
import classNames from 'classnames';
import { logout } from '../hooks/auth';
import { AlertCircle, RefreshCw } from 'lucide-react';
import { GetPermissionsBlacklist } from '../hooks/firebase';

export default function Layout({ children }: { children: ReactNode }) {
	const location = useLocation();

	const { authenticated, userData, shouldLogin, internal, accessToOtherStores, newVersionAvailable, internalPath } =
		useContext(GlobalContext);
	const permissionsBlacklist = GetPermissionsBlacklist(userData?.master_store.id || null, userData?.email || null);

	if (shouldLogin && location.pathname !== '/login') {
		return <Navigate to="/login" replace />;
	}
	if (!shouldLogin && location.pathname === '/login') {
		return <Navigate to="/" replace />;
	}
	if (!accessToOtherStores && location.pathname === '/log-in-as') {
		return <Navigate to="/" replace />;
	}
	if (!internal && location.pathname === '/internal') {
		return <Navigate to="/" replace />;
	}
	if (internal && location.pathname !== '/internal') {
		return <Navigate to="/internal" replace />;
	}
	if (
		(permissionsBlacklist?.includes('delete_sale') && location.pathname === '/deleteSale') ||
		(permissionsBlacklist?.includes('delete_payment') && location.pathname === '/deletePayment') ||
		(permissionsBlacklist?.includes('change_sale_price') && location.pathname === '/changePrice') ||
		(permissionsBlacklist?.includes('register_payment') && location.pathname === '/buscarcliente')
	) {
		return <Navigate to="/" replace />;
	}

	const showContent = ['/login', '/logout', '/internal'].includes(location.pathname) || (userData && authenticated && permissionsBlacklist);
	const showOnboarding =
		!userData?.cobranza_access &&
		userData?.registration_steps &&
		!Object.values(userData.registration_steps).every((step) => step === true);
	const showNavigation = showContent && !showOnboarding && !['/login'].includes(location.pathname);

	return (
		<div className="flex h-svh flex-col-reverse text-neutral-100 light:bg-background dark:bg-background md:flex-row light:md:bg-foreground">
			{showNavigation && (
				<Navigation
					userData={userData!}
					accessToOtherStores={accessToOtherStores}
					internalPath={internalPath}
					internal={internal}
					permissionsBlacklist={permissionsBlacklist}
				/>
			)}

			<div
				className={classNames(
					'mb-[4.5rem] h-full w-full overflow-y-auto md:ml-[4rem] md:flex-[1] md:overflow-y-hidden md:p-[1.25rem] xl:ml-[18rem]',
					{
						'!mb-0 !ml-0': !showNavigation
					}
				)}
			>
				<div className="h-full w-full light:bg-background md:overflow-y-auto md:rounded-xl md:bg-foreground">
					{showContent && showOnboarding && <Onboarding />}
					{showContent && !showOnboarding && children}
					{!showContent && <ContainerSpinner size={3} />}
				</div>
			</div>

			{/* {newVersionAvailable && (
				<div className="fixed inset-x-0 top-0 z-[9999] select-none bg-primary-500 shadow-lg backdrop-blur-sm">
					<div className="mx-auto max-w-4xl px-4 py-6">
						<div className="flex flex-col items-center justify-between gap-4 md:flex-row">
							<div className="hidden size-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 md:flex">
								<RefreshCw className="h-6 w-6 text-primary-600" />
							</div>

							<div className="flex-1">
								<h3 className="flex items-center justify-center gap-2 font-semibold text-white light:text-black md:block md:text-lg">
									<RefreshCw className="size-4 md:hidden" />
									¡Nueva actualización disponible!
								</h3>
								<p className="mt-1 text-center text-sm text-primary-100 md:text-left light:text-neutral-200">
									Debe refrescar la página y volver a iniciar sesión para actualizar y evitar errores.
								</p>
							</div>

							<div className="flex flex-shrink-0 items-center gap-4">
								<button
									onClick={() => {
										logout(() => {
											window.location.reload();
										});
									}}
									className="inline-flex items-center gap-2 rounded-lg bg-white px-4 py-2 text-sm font-semibold text-primary-600 shadow-sm transition-all hover:bg-blue-50 hover:shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
								>
									<AlertCircle className="h-4 w-4" />
									Actualizar ahora
								</button>
							</div>
						</div>
					</div>
				</div>
			)} */}
		</div>
	);
}
