import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, Button, TextField, MenuItem } from '@material-ui/core';
import { Button as Button2 } from '../shadcn/Button';
import firestore from '../firestore';
import { GlobalContext } from '../context/GlobalContext';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { ContainerSpinner } from '../components/Spinner';
import Pay from './pay';
import { GetBanks } from '../hooks/firebase';
import useFetch, { unsafeAPI } from '../hooks/api';
import { Dialog } from '../components/Dialog';
import Input from '../components/Input';
import Select from '../components/Select';
import { validate_bank_account_number, validate_email, validate_identity_number } from '../utils/validate';
import Notice from '../components/Notice';
import Table from '../components/Table';
import { ucfirst } from '../utils/formatters';
import { AlertTriangle, Ban, CheckCircle, Copy, Lock, Pencil, RefreshCcw, Search, SearchIcon, Trash, Unlock } from 'lucide-react';
import { ConfirmInputDialog } from '../components/ConfirmInputDialog';
import { ConfirmDialog } from '../components/ConfirmDialog';

const useStyles = makeStyles({
	root: {
		width: '1500px',
		height: '1200px',
		color: 'white',
		flexGrow: 1,
		margin: '0 auto'
	},
	title: {
		color: '#ffffff',
		fontSize: 20,
		marginLeft: 40
	},
	title2: {
		color: 'white',
		marginBottom: 20,
		fontSize: 26,
		fontWeight: 'bold',
		marginLeft: 20
	},
	cell: {
		color: 'silver',
		backgroundColor: '#1C1C1E'
	},
	font: { color: 'white' },
	welcomeButtons: {
		backgroundColor: '#45C4D6',
		color: '#2A2E32',
		marginTop: 30,
		marginBottom: 20
	},
	textSmall5: {
		fontSize: 16,
		color: '#2A2E32',
		fontWeight: 'bold'
	},
	textFields: {
		backgroundColor: '#2A2E32',
		color: 'white',
		borderColor: 'white',
		borderBottomColor: 'white',
		borderRadius: 5,
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		paddingLeft: 5,
		width: 200,
		marginLeft: 40
	},
	textFields2: {
		backgroundColor: 'white',
		color: '#2A2E32',
		borderColor: 'white',
		borderBottomColor: 'white',
		borderRadius: 5,
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		paddingRight: 500,
		marginLeft: 40
	}
});

const Internal = () => {
	const { userData, internalPath } = useContext(GlobalContext);
	const ata = userData?.access_type === 'ata';

	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);
	const [storeCommercialName, setStoreCommercialName] = useState('');
	const [storeEmail, setStoreEmail] = useState('');
	const [cedula3, setCedula3] = useState('');

	const [deviceID2, setDeviceID2] = useState('');
	const [storeToChange, setStoreToChange] = useState('');
	const [limitIncrease, setLimitInrease] = useState('');
	const [paymentDate, setPaymentDate] = useState('');
	const [paymentDate2, setPaymentDate2] = useState('');
	const [referenceNumber2, setReferenceNumber2] = useState('');
	const [reportedAmount2, setReportedAmount2] = useState('');
	const [referenceNumber, setReferenceNumber] = useState('');
	const [reportedAmount, setReportedAmount] = useState('');
	const [cobranzaKpi, setCobranzaKpi] = useState('');
	const [showCobranzaKpi, setShowCobranzaKpi] = useState('');
	const [salesSum, setSalesSum] = useState(0);
	const [masterEmail, setMasterEmail] = useState('');
	const [clientType, setClientType] = useState(true);
	const [isEntel, setIsEntel] = useState(false);
	const [file, setFile] = useState(null);

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			setFile(selectedFile);
		}
	};

	const parseCSV = (csvString) => {
		const results = {};
		Papa.parse(csvString, {
			header: true,
			complete: (data) => {
				data.data.forEach((row) => {
					results[row['Nombre de Aliado']] = row['ID'];
				});
			}
		});
		return results;
	};

	const exportTiendasCSV = async () => {
		if (!file) {
			alert('Please upload a CSV file first.');
			return;
		}

		try {
			const csvString = await file.text();
			const aliados = parseCSV(csvString);

			const storeInfoCollection = firestore.collection('StoreInfo');

			// Fetch all documents from the StoreInfo collection
			const querySnapshot = await storeInfoCollection.get();

			// Array to store CSV data
			const csvData = [];

			querySnapshot.forEach((doc) => {
				const data = doc.data();
				const storeName = data.store_name || '';

				if (Array.isArray(data.sub_stores)) {
					data.sub_stores.forEach((subStore) => {
						if (!aliados[storeName]) {
							console.log('Store: ' + storeName);
						}
						const storeRow = {
							'Nombre de la tienda': subStore.store_name || '',
							'Link de google maps': `https://www.google.com/maps/search/?api=1&query=${subStore.location || ''}`,
							Horario: subStore.hours_of_operation || '',
							Aliado: aliados[storeName] || '',
							'Direccion corta': subStore.location || '',
							Ciudad: JSON.stringify(Array.from([subStore.city])) || '',
							'Ubicacion maps': JSON.stringify({ formatted: subStore.location, city: subStore.city }) // `${subStore.location} ${subStore.city || ''}`, // Assuming Wix format includes city as well
						};

						// Push the row into csvData array
						if (data.country === userData.country) {
							csvData.push(storeRow);
						}
					});
				}
			});

			// Use PapaParse to convert JSON array to CSV string
			const csvStringOutput = Papa.unparse(csvData);

			// Create a Blob object for the CSV file
			const blob = new Blob([csvStringOutput], { type: 'text/csv;charset=utf-8;' });

			// Create a link to download the CSV file
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.setAttribute('download', 'Tiendas.csv');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			console.log('Tiendas.csv file created successfully!');
		} catch (error) {
			console.error('Error exporting Tiendas to CSV:', error);
		}
	};

	async function exportAliadosCSV() {
		try {
			const storeInfoCollection = firestore.collection('StoreInfo');

			// Fetch all documents from the StoreInfo collection
			const querySnapshot = await storeInfoCollection.get();

			// Array to store CSV data
			const csvData = [];

			// Iterate through each document and extract required fields
			querySnapshot.forEach((doc) => {
				const data = doc.data();

				// Extracting the required fields from the document
				const storeName = data.store_name || '';
				const logoUrl = data.logo_url || '';
				const whatsappLink = data.contact?.whatsapp_link || '';
				const instagramLink = data.contact?.instagram_link || '';

				// Extract and format cities from the `sub_stores` array using a Set to ensure uniqueness
				const uniqueCities = new Set();
				if (Array.isArray(data.sub_stores)) {
					data.sub_stores.forEach((store) => {
						if (store.city) {
							uniqueCities.add(store.city); // Only add unique cities
						}
					});
				}

				// Convert the Set to an array and format as ["City1", "City2", "City3"]
				const formattedCities = JSON.stringify(Array.from(uniqueCities));

				// Create a row for each document with the desired fields
				const row = {
					'Nombre de Aliado': storeName,
					'Logo/Marca': logoUrl,
					'Whatsapp Link': whatsappLink,
					Instagram: instagramLink,
					Ciudades: formattedCities
				};

				// Push the row into csvData array
				if (data.country === userData.country) {
					csvData.push(row);
				}
			});

			// Use PapaParse to convert JSON array to CSV string
			const csvString = Papa.unparse(csvData);

			// Create a Blob object for the CSV file
			const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

			// Use FileSaver to save the CSV file with the name Aliados.csv
			saveAs(blob, 'Aliados.csv');

			console.log('CSV file created successfully!');
		} catch (error) {
			console.error('Error exporting Aliados to CSV:', error);
		}
	}

	const getStoreTransactions = async () => {
		try {
			if (!reportedAmount || !paymentDate || !storeEmail) {
				alert('Por favor rellene todos los datos');
				return;
			}
			setIsLoading(true);
			const data = {
				store_email: storeEmail.trim(),
				amount: convertToDotDecimal(reportedAmount),
				date: formatForAPI(paymentDate)
			};
			const response = await sendPostRequest(data, 'find_payment_based_on_price/', process.env.REACT_APP_API_TOKEN); // change for the righ enpoint name
			if (response?.success) {
				alert(
					response.status +
						'. Se encontraron los siguientes números de referencia: ' +
						JSON.stringify(response.data.map((obj) => obj.reference))
				);
			} else if (!response?.success) {
				alert('Error: ' + response.status);
			} else {
				alert('Ocurrio un problema intente mas tarde.');
			}
			setIsLoading(false);
		} catch (err) {
			alert('Error: ' + err.message);
		}
	};

	async function exportCitiesCSV() {
		try {
			const storeInfoCollection = firestore.collection('StoreInfo');

			// Fetch all documents from the StoreInfo collection
			const querySnapshot = await storeInfoCollection.get();

			// Set to store unique cities
			const uniqueCities = new Set();

			// Iterate through each document
			querySnapshot.forEach((doc) => {
				const data = doc.data();

				// Check if the document has the 'sub_stores' field and it is an array
				if (Array.isArray(data.sub_stores)) {
					// Loop through each item in sub_stores array
					data.sub_stores.forEach((store) => {
						if (store.city && data.country === userData.country) {
							// Add the city to the Set to ensure uniqueness
							uniqueCities.add(store.city);
						}
					});
				}
			});

			// Convert the Set to an array for easier manipulation
			const citiesArray = Array.from(uniqueCities);

			// Create a CSV formatted object
			const csvData = citiesArray.map((city) => ({ Title: city }));

			// Use PapaParse to convert JSON array to CSV string
			const csvString = Papa.unparse(csvData);

			// Create a Blob object for the CSV file
			const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

			// Use FileSaver to save the CSV file with the name Ciudades.csv
			saveAs(blob, 'Ciudades.csv');

			console.log('CSV file created successfully!');
		} catch (error) {
			console.error('Error exporting cities to CSV:', error);
		}
	}

	const sendPostRequest = async (data, endpoint, token2) => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: token2
					// 'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
					// 'x-master-store-email': 'admin@krece.app',
					// 'x-user-email': null
				},
				body: JSON.stringify({ ...data, country: userData.country, store_user: userData.email })
			});
			const responseData = await response.json();

			if (response.status === '401') {
				const tokenData = await requestToken();

				const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token);
				return resData;
			}
			return responseData;
		} catch (error) {
			console.error('Error:', error.message);
			return null;
		}
	};

	const requestToken = async () => {
		const loginData = {
			username: process.env.REACT_APP_TOKEN_USERNAME,
			password: process.env.REACT_APP_TOKEN_PASSWORD
		};
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(loginData)
			});

			const responseData = await response.json();
			return responseData;
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const registerIncorrectPayment = async () => {
		setIsLoading(true);
		if (!paymentDate2 || !reportedAmount2 || !referenceNumber2) {
			alert('Por favor rellene todos los datos');
			setIsLoading(false);
			return;
		}
		try {
			const data = {
				reference: referenceNumber2,
				amount: convertToDotDecimal(reportedAmount2),
				date: formatForAPI(paymentDate2)
			};
			const response = await sendPostRequest(data, 'register_incorrect_payment/', process.env.REACT_APP_API_TOKEN);
			if (response?.success) {
				alert('Registro Exitoso');
			} else if (!response?.success) {
				alert('Error: ' + response.status);
			} else {
				alert('Ocurrio un problema intente mas tarde.');
			}
			setIsLoading(false);
		} catch (err) {
			alert('Error: ' + err.message);
		}
	};

	const getSalesKpi = async () => {
		setIsLoading(true);
		try {
			await firestore
				.collection('History')
				.doc('DailyDeviceLimits')
				.get()
				.then((doc) => {
					let sum = 0;
					const data = doc.data();
					for (const [key, value] of Object.entries(data)) {
						if (!key.includes('BitMercado')) {
							sum = sum + value.devices_sold;
						}
					}
					setSalesSum(sum);
				});
			setIsLoading(false);
		} catch (err) {
			console.log(err.message);
			alert('Ocurrio un problema');
			setIsLoading(false);
		}
	};

	const getCobranzaKpi = async () => {
		setIsLoading(true);
		try {
			const data = {
				cobranza_owner: '18761844@krece.app'
			};
			const response = await sendPostRequest(data, 'get_cobranza_stats/', process.env.REACT_APP_API_TOKEN);
			if (response?.success) {
				setCobranzaKpi(JSON.stringify(response.data));
				setShowCobranzaKpi(true);
				setIsLoading(false);
			} else if (!response?.success) {
				alert('Error: ' + response.status);
				setIsLoading(false);
			} else {
				alert('Ocurrio un problema intente mas tarde.');
				setIsLoading(false);
			}
		} catch (err) {
			alert('Error: ' + err.message);
			setIsLoading(false);
		}
	};

	const changePaymentDate15and30 = async () => {
		setIsLoading(true);
		if (!cedula3) {
			alert('Por favor rellene todos los datos');
			setIsLoading(false);
			return;
		}
		try {
			const data = {
				identification_number: cedula3.trim()
			};
			const response = await sendPostRequest(data, 'update_payments_to_15_30/', process.env.REACT_APP_API_TOKEN);

			if (response?.success) {
				alert('Fechas de pago cambiadas exitosamente');
			} else if (!response?.success) {
				alert('Error: ' + response.status);
			} else {
				alert('Ocurrio un problema intente mas tarde.');
			}
			setIsLoading(false);
		} catch (err) {
			alert(err.message);
		}
	};

	const increaseDeviceLimit = async () => {
		setIsLoading(true);
		if (!storeToChange || !limitIncrease) {
			alert('Por favor rellene todos los datos');
			setIsLoading(false);
			return;
		}
		try {
			const data = {
				store_name: storeToChange,
				device_limit_increase: parseInt(limitIncrease)
			};
			const response = await sendPostRequest(data, 'increase_device_limit/', process.env.REACT_APP_API_TOKEN);

			if (response?.success) {
				alert('Límite incrementado exitosamente');
			} else if (!response?.success) {
				alert('Error: ' + response.status);
			} else {
				alert('Ocurrio un problema intente mas tarde.');
			}
			setIsLoading(false);
		} catch (err) {
			alert(err.message);
		}
	};

	const searchTransaction = async () => {
		try {
			if (!reportedAmount || !paymentDate || !referenceNumber || !deviceID2) {
				alert('Por favor rellene todos los datos');
				return;
			}
			setIsLoading(true);
			const data = {
				reference: referenceNumber.trim(),
				amount: convertToDotDecimal(reportedAmount),
				date: formatForAPI(paymentDate)
			};
			const response = await sendPostRequest(data, 'find_payment/', process.env.REACT_APP_API_TOKEN); // change for the righ enpoint name
			if (response?.success) {
				alert(response.status);
			} else if (!response?.success) {
				alert('Error: ' + response.status);
			} else {
				alert('Ocurrio un problema intente mas tarde.');
			}
			setIsLoading(false);
		} catch (err) {
			alert('Error: ' + err.message);
		}
	};

	function convertToDotDecimal(str) {
		if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
			return str.replace(/,/g, '.');
		} else {
			return str;
		}
	}

	function formatForAPI(date) {
		// Format the date as yyyy/mm/dd
		const formattedDate = date
			.toLocaleString('en-CA', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				timeZone: 'America/New_York' // GMT-4 timezone (Eastern Time)
			})
			.replace(/\//g, '-'); // Replace slashes with dashes

		return formattedDate;
	}

	const createMasterAccount = async () => {
		setIsLoading(true);
		if ((userData.country === 'venezuela' && clientType === '') || !storeCommercialName || !masterEmail) {
			alert('Por favor rellene todos los datos.');
			setIsLoading(false);
			return;
		}
		try {
			const data = {
				financed_by_krece: true,
				country: userData.country,
				entel_parter: isEntel,
				store_name: storeCommercialName,
				master_store_email: masterEmail
			};

			const response = await sendPostRequest(data, 'storeprofile/masteruser/', process.env.REACT_APP_API_TOKEN);

			if (response?.success) {
				alert('Cuenta creada con exito!');
			} else if (!response?.success) {
				alert('Error: ' + response.status);
			} else {
				alert('Ocurrio un problema intente mas tarde.');
			}
			setIsLoading(false);
		} catch (err) {
			alert('Error: ' + err.message);
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <ContainerSpinner size={3} />;
	}

	return (
		<>
			{(internalPath.get() === '/buscar-clientes' || internalPath.get() === '') && <GetClientData />}
			{internalPath.get() === '/registrar-pago' && <Pay />}
			{internalPath.get() === '/pagos-por-verificar' && <VerifyPayments />}
			{internalPath.get() === '/onboarding' && (
				<div className="mx-auto w-max p-4 md:p-[1.25rem]">
					<h2 className={classes.title2}>Crear cuenta master</h2>
					<TextField
						label="Correo cuenta master"
						name="masterEmail"
						value={masterEmail}
						onChange={(e) => setMasterEmail(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<TextField
						label="Nombre del comercio"
						name="storeCommercialName"
						value={storeCommercialName}
						onChange={(e) => setStoreCommercialName(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					{userData.country === 'peru' && (
						<TextField
							select
							label="Referido por Entel"
							name="isEntel"
							value={isEntel}
							onChange={(e) => isEntel(e.target.value)}
							className={classes.textFields}
							margin="normal"
							fullWidth
							InputProps={{
								style: {
									color: 'white', // Text color
									borderBottom: '1px solid white'
								}
							}}
							InputLabelProps={{
								style: {
									color: '#D3D3D3' // Label color
								}
							}}
						>
							<MenuItem value={false}>No</MenuItem>
							<MenuItem value={true}>Si</MenuItem>
						</TextField>
					)}
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={createMasterAccount}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Crear Cuenta
					</Button>
					<h2 className={classes.title2}>Wix Update</h2>
					<h2 className={classes.title}>1. Exportar este CSV e importarlo en el collection Ciudades en Wix</h2>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={exportCitiesCSV}
						style={{ marginTop: '10px', marginLeft: 40 }}
					>
						Exportar Ciudades.CSV
					</Button>
					<h2 className={classes.title}>2. Exportar este CSV e importarlo en el collection Aliados en Wix</h2>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={exportAliadosCSV}
						style={{ marginTop: '10px', marginLeft: 40 }}
					>
						Exportar Aliados.CSV
					</Button>
					<h2 className={classes.title}>3. Ir a Wix y exportar el CSV generado de aliados e importarlo aqui</h2>
					<input
						type="file"
						accept=".csv"
						onChange={handleFileChange}
						style={{ marginLeft: 40, marginUp: 10, marginBottom: 20 }}
					/>
					<h2 className={classes.title}>4. Exportar este CSV e importarlo en el collection Tiendas en Wix</h2>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={exportTiendasCSV}
						style={{ marginTop: '10px', marginLeft: 40 }}
					>
						Exportar Tiendas.CSV
					</Button>
				</div>
			)}
			{internalPath.get() === '/kpis' && (
				<div className="mx-auto w-max p-4 md:p-[1.25rem]">
					<h2 className={classes.title} style={{ marginLeft: 20, fontSize: 24 }}>
						KPIs
					</h2>
					<h2 className={classes.title} style={{ marginLeft: 20, fontSize: 20, marginTop: 30 }}>
						Ventas del dia
					</h2>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={getSalesKpi}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Obtener KPI
					</Button>
					{salesSum > 0 && <span style={{ color: '#2A2E32', marginLeft: 30 }}>{salesSum}</span>}
					<h2 className={classes.title} style={{ marginLeft: 20, fontSize: 20, marginTop: 30 }}>
						Cobranza
					</h2>

					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={getCobranzaKpi}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Obtener KPI
					</Button>
					{showCobranzaKpi && <span style={{ color: '#2A2E32', marginLeft: 30 }}>{cobranzaKpi}</span>}
				</div>
			)}
			{internalPath.get() === '/buscar-pagos-de-tienda' && (
				<div className="mx-auto w-max p-4 md:p-[1.25rem]">
					<h2 className={classes.title} style={{ marginTop: 20 }}>
						Buscar pagos de tienda
					</h2>
					<TextField
						label="Monto"
						name="reportedAmount"
						type="tel"
						value={reportedAmount}
						onChange={(e) => setReportedAmount(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<TextField
						label="Fecha de Pago"
						name="paymentDate"
						type="date"
						value={paymentDate}
						onChange={(e) => setPaymentDate(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							},
							shrink: true
						}}
					/>
					<TextField
						label="Email de la tienda"
						name="storeEmail"
						value={storeEmail}
						onChange={(e) => setStoreEmail(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							},
							shrink: true
						}}
					/>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={getStoreTransactions}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Buscar
					</Button>
				</div>
			)}
			{internalPath.get() === '/buscar-pago-en-banco' && (
				<div className="mx-auto w-max p-4 md:p-[1.25rem]">
					<h2 className={classes.title} style={{ marginTop: 20 }}>
						Buscar pago en Banco
					</h2>
					<TextField
						label="Monto"
						name="reportedAmount"
						type="tel"
						value={reportedAmount}
						onChange={(e) => setReportedAmount(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<TextField
						label="Referencia"
						name="referenceNumber"
						type="tel"
						value={referenceNumber}
						onChange={(e) => setReferenceNumber(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<TextField
						label="Fecha de Pago"
						name="paymentDate"
						type="date"
						value={paymentDate}
						onChange={(e) => setPaymentDate(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<TextField
						label="ID de producto"
						name="deviceID2"
						value={deviceID2}
						onChange={(e) => setDeviceID2(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={searchTransaction}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Buscar
					</Button>
				</div>
			)}
			{internalPath.get() === '/registrar-pago-reembolsado' && (
				<div className="mx-auto w-max p-4 md:p-[1.25rem]">
					{' '}
					<h2 className={classes.title} style={{ marginTop: 20 }}>
						Registrar pago reembolsado
					</h2>
					<TextField
						label="Monto"
						name="reportedAmount2"
						type="tel"
						value={reportedAmount2}
						onChange={(e) => setReportedAmount2(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<TextField
						label="Referencia"
						name="referenceNumber2"
						type="tel"
						value={referenceNumber2}
						onChange={(e) => setReferenceNumber2(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<TextField
						label="Fecha de Pago"
						name="paymentDate2"
						type="date"
						value={paymentDate2}
						onChange={(e) => setPaymentDate2(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							},
							shrink: true
						}}
					/>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={registerIncorrectPayment}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Registrar
					</Button>
				</div>
			)}
			{internalPath.get() === '/cambiar-fecha-a-15-y-ultimo' && (
				<div className="mx-auto w-max p-4 md:p-[1.25rem]">
					<h2 className={classes.title} style={{ marginTop: 20 }}>
						Cambiar fecha de pagos a 15 y último
					</h2>
					<TextField
						label="Cedula"
						name="cedula3"
						type="tel"
						value={cedula3}
						onChange={(e) => setCedula3(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={changePaymentDate15and30}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Cambiar
					</Button>
				</div>
			)}
			{internalPath.get() === '/subir-limite-de-cupos' && (
				<div className="mx-auto w-max p-4 md:p-[1.25rem]">
					<h2 className={classes.title} style={{ marginTop: 20 }}>
						Subir limite de cupos
					</h2>
					<TextField
						select
						label="Aliado"
						name="store"
						value={storeToChange}
						onChange={(e) => setStoreToChange(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					>
						<MenuItem value="ApplyMovil">ApplyMovil</MenuItem>
						<MenuItem value="BitMercadoCityMarket">Bit Mercado City</MenuItem>
						<MenuItem value="BitMercadoDigital">Bit Mercado Millennium/Marques</MenuItem>
						<MenuItem value="77JMovilnet">77J</MenuItem>
						<MenuItem value="TECNO_3107">Tecno 3107</MenuItem>
						<MenuItem value="MobileShop">Mobile Shop</MenuItem>
						<MenuItem value="GlobalMarket">Global Market</MenuItem>
						<MenuItem value="GoStore">GoStore</MenuItem>
					</TextField>
					<TextField
						label="Subir por:"
						name="limitIncrease"
						type="tel"
						value={limitIncrease}
						onChange={(e) => setLimitInrease(e.target.value)}
						className={classes.textFields}
						margin="normal"
						fullWidth
						InputProps={{
							style: {
								color: 'white', // Text color
								borderBottom: '1px solid white'
							}
						}}
						InputLabelProps={{
							style: {
								color: '#D3D3D3' // Label color
							}
						}}
					/>
					<Button
						variant="contained"
						className={classes.welcomeButtons}
						onClick={increaseDeviceLimit}
						style={{ marginTop: '10px', marginLeft: 20 }}
					>
						Subir Límite
					</Button>
				</div>
			)}
		</>
	);

	function GetClientData() {
		const [dialogMessage, setDialogMessage] = useState('');
		/** @type {[null | import('../components/ConfirmDialog').ConfirmDialogProps, Function]} */
		const [confirmDialogProps, setConfirmDialogProps] = useState(null);
		/** @type {[null | import('../components/ConfirmInputDialog').ConfirmInputDialogProps, Function]} */
		const [editDialogProps, setEditDialogProps] = useState(null);

		const [identificationValue, setIdentificationValue] = useState('');
		const [identificationType, setIdentificationType] = useState('identification_number');

		const [loading, setLoading] = useState(false);
		const [checkingDeviceStatus, setCheckingDeviceStatus] = useState(false);

		/** @type {[ClientData | undefined, React.Dispatch<React.SetStateAction<Root | undefined>>]} */
		const [clientData, setClientData] = useState();
		const [unrelatedTransactions, setUnrelatedTransactions] = useState([]);

		const searchClientData = async () => {
			if (!identificationType || !identificationValue) {
				setDialogMessage('Por favor rellene todos los datos');
				return;
			}

			setClientData(undefined);
			setLoading(true);
			try {
				const data = {
					KeyType: identificationType,
					KeyValue: identificationValue.toLowerCase().trim()
				};
				const response = await sendPostRequest(data, 'get_user_data/', process.env.REACT_APP_API_TOKEN);

				if (response?.success === true && response?.data) {
					const responseData = JSON.parse(response.data);
					const clientPoints =
						(responseData?.user?.devices_paid?.length || 0) * 20 +
						(responseData?.user?.ontime_payments_ids?.length || 0) * 10 -
						(responseData?.user?.late_payments_ids?.length || 0) * 10;
					const clientLevel =
						responseData?.user?.level === 'level_1'
							? 'Azul'
							: responseData?.user?.level === 'level_2'
								? 'Plata'
								: responseData?.user?.level === 'level_3'
									? 'Oro'
									: responseData?.user?.level === 'level_4'
										? 'Platino'
										: null;
					const transformedData = {
						user: { ...responseData.user, points: clientPoints, level: clientLevel },
						devices: responseData.devices.map((device) => ({
							...device,
							payments: responseData.payments.filter((payment) => payment.device === device.id),
							transactions: responseData.transactions.filter((transaction) =>
								responseData.payments.some(
									(payment) => payment.device === device.id && payment.id === transaction.related_payment
								)
							)
						}))
					};

					setClientData(transformedData);
					setUnrelatedTransactions(
						responseData.transactions.filter(
							(transaction) => !responseData.payments.some((payment) => payment.id === transaction.related_payment)
						)
					);
				} else {
					setClientData(false);
				}
			} catch (err) {
				setDialogMessage(err.message);
			} finally {
				setLoading(false);
			}
		};

		const unlockDevice = async (device_id) => {
			if (typeof device_id !== 'string') return 'Por favor ingrese un ID de dispositivo válido.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'unlock_phone/',
					{
						device_id
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						devices: prev.devices.map((device) => (device.id === device_id ? { ...device, is_locked: false } : device))
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const freeDevice = async (device_id) => {
			if (typeof device_id !== 'string') return 'Por favor ingrese un ID de dispositivo válido.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'release_device/',
					{
						device_id
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						devices: prev.devices.map((device) => (device.id === device_id ? { ...device, is_released: true } : device))
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const reportStolenDevice = async (device_id) => {
			if (typeof device_id !== 'string') return 'Por favor ingrese un ID de dispositivo válido.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'report_device_stolen/',
					{
						device_id
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						devices: prev.devices.map((device) => (device.id === device_id ? { ...device, is_stolen: true } : device))
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const checkDeviceStatus = async (device_imei_no) => {
			setCheckingDeviceStatus(true);

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'administration/devicestatus/',
					{ device_imei_no },
					userData
				);

				if (response?.success === true && response?.status?.length) {
					setDialogMessage(response.status);
				} else {
					setDialogMessage(response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.');
				}
			} catch (err) {
				setDialogMessage('Error: ' + err.message);
			} finally {
				setCheckingDeviceStatus(false);
			}
		};

		const changeCreditLine = async (new_value) => {
			if (typeof new_value !== 'string' || !new_value?.trim() || Number.isNaN(Number(new_value)))
				return 'Por favor ingrese un número válido.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'change_linea_de_compra/',
					{
						identification_number: clientData.user.identification_number,
						nueva_linea_de_compra: parseInt(new_value?.trim())
					},
					userData
				);

				if (response?.success === true && response?.status?.length) {
					setClientData((prev) => ({
						...prev,
						user: {
							...prev.user,
							max_loan_approved_3months: parseInt(new_value?.trim())
						}
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const changeName = async (new_value) => {
			if (typeof new_value !== 'string' || !new_value?.trim() || new_value?.trim()?.length < 3)
				return 'El nombre debe tener al menos 3 caracteres.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'change_user_name/',
					{
						identification_number: clientData.user.identification_number,
						first_name: new_value.toUpperCase().trim()
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						user: {
							...prev.user,
							first_name: new_value.toUpperCase().trim()
						}
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const changeLastName = async (new_value) => {
			if (typeof new_value !== 'string' || !new_value?.trim() || new_value?.trim()?.length < 3)
				return 'Los apellidos deben tener al menos 3 caracteres.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'change_user_name/',
					{
						identification_number: clientData.user.identification_number,
						last_name: new_value.toUpperCase().trim()
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						user: {
							...prev.user,
							last_name: new_value.toUpperCase().trim()
						}
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const changeIdentificationNumber = async (new_value) => {
			if (
				typeof new_value !== 'string' ||
				(!validate_identity_number(new_value?.trim() || '', 'cedula', userData?.country) &&
					!validate_identity_number(new_value?.trim() || '', 'dni', userData?.country))
			)
				return 'El número de cédula o DNI no es válido.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'change_identification_number/',
					{
						identification_number: clientData.user.identification_number,
						identification_number_new: new_value.trim()
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						user: {
							...prev.user,
							identification_number: new_value.trim()
						}
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const changePhoneNumber = async (new_value) => {
			const phoneRegex = /^\d{9,11}$/;
			if (typeof new_value !== 'string' || !phoneRegex.test(new_value?.trim())) {
				return 'Por favor ingrese un número de teléfono de 9-11 digitos.';
			}

			try {
				const usersCollection = firestore.collection('Users');

				const querySnapshot = await usersCollection
					.where('identification_number', '==', clientData.user.identification_number)
					.limit(1)
					.get();

				const doc = querySnapshot?.docs?.[0];
				if (!doc || !doc?.exists) {
					throw new Error('No existe un usuario con esta Cédula o DNI.');
				}

				doc.ref.update({
					phone_number: new_value?.trim()
				});

				setClientData((prev) => ({
					...prev,
					user: {
						...prev.user,
						phone_number: new_value?.trim()
					}
				}));
			} catch (err) {
				return 'Error: ' + (err.message || 'Ocurrió un problema, intente de nuevo más tarde.');
			}

			return true;
		};

		const changeEmail = async (new_value) => {
			if (typeof new_value !== 'string' || !validate_email(new_value?.trim())) return 'El email no es válido.';

			try {
				const usersCollection = firestore.collection('Users');
				const paymentsCollection = firestore.collection('Payments');
				const devicesCollection = firestore.collection('Devices');
				const remindersCollection = firestore.collection('Reminders');
				const transactionsCollection = firestore.collection('Transactions');

				const querySnapshot = await usersCollection
					.where('identification_number', '==', clientData.user.identification_number)
					.limit(1)
					.get();

				const doc = querySnapshot?.docs?.[0];
				const dataToClone = doc?.data();

				if (!dataToClone) {
					throw new Error('No se encontró el usuario.');
				}
				if (doc.id === new_value.toLowerCase().trim()) {
					throw new Error('El correo introducido es igual al anterior, por favor introduzca un correo distinto.');
				}

				const destinationDocumentRef = firestore.collection('Users').doc(new_value.toLowerCase().trim());
				await destinationDocumentRef.set(dataToClone);
				if (dataToClone['registration_status'] === 'device_enrolled') {
					//change "user" field in Devices for this user
					const deviceQuery = devicesCollection.where('user', '==', usersCollection.doc(doc.id));
					for (const doc of (await deviceQuery.get()).docs) {
						await doc.ref.update({
							user: usersCollection.doc(new_value.toLowerCase().trim())
						});
					}

					//change payment "user" field to the new user
					const paymentQuery = paymentsCollection.where('user', '==', usersCollection.doc(doc.id));
					for (const doc of (await paymentQuery.get()).docs) {
						await doc.ref.update({
							user: usersCollection.doc(new_value.toLowerCase().trim())
						});
					}

					//change the Reminders "user" field to the new user
					const reminderQuery = remindersCollection.where('user', '==', usersCollection.doc(doc.id));
					for (const doc of (await reminderQuery.get()).docs) {
						await doc.ref.update({
							user: usersCollection.doc(new_value.toLowerCase().trim())
						});
					}

					//change the Transactions "user" field to the new user
					const transactionQuery = transactionsCollection.where('user', '==', usersCollection.doc(doc.id));
					for (const doc of (await transactionQuery.get()).docs) {
						await doc.ref.update({
							user: usersCollection.doc(new_value.toLowerCase().trim())
						});
					}

					//delete the old user
					await doc.ref.delete();
				} else {
					await doc.ref.delete();
				}

				setClientData((prev) => ({
					...prev,
					user: {
						...prev.user,
						email: new_value.toLowerCase().trim()
					}
				}));
			} catch (err) {
				return 'Error: ' + (err.message || 'Ocurrió un problema, intente de nuevo más tarde.');
			}

			return true;
		};

		const changeCommercialName = async (device_id, new_value) => {
			if (typeof new_value !== 'string' || !new_value?.trim() || new_value?.trim()?.length < 4)
				return 'El nombre  debe tener al menos 4 caracteres.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'change_device_commercial_name/',
					{
						device_id,
						device_commercial_name: new_value.trim()
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						devices: prev.devices.map((device) =>
							device.id === device_id ? { ...device, device_commercial_name: new_value.trim() } : device
						)
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const changeSalePrice = async (device_id, new_value) => {
			if (typeof new_value !== 'string' || !new_value?.trim() || Number.isNaN(Number(new_value)))
				return 'Por favor ingrese un número válido.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'change_device_selling_price/',
					{
						device_id,
						device_price: parseInt(new_value.trim())
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						devices: prev.devices.map((device) =>
							device.id === device_id ? { ...device, device_price: parseInt(new_value.trim()) } : device
						)
					}));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const deleteTransaction = async (transaction_id) => {
			if (typeof transaction_id !== 'string') return 'Por favor ingrese un ID de transacción válido.';

			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'delete_payment/',
					{
						transaction_id
					},
					userData
				);

				if (response?.success === true) {
					setClientData((prev) => ({
						...prev,
						devices: prev.devices.map((device) => {
							const transactions = device.transactions.filter((transaction) => transaction.id !== transaction_id);
							device.transactions = transactions;
							return device;
						})
					}));
					setUnrelatedTransactions((prev) => prev?.filter((transaction) => transaction.id !== transaction_id));
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const deleteAccount = async () => {
			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'delete_user/',
					{
						identification_number: clientData.user.identification_number
					},
					userData
				);

				if (response?.success === true) {
					setDialogMessage('Cuenta eliminada exitosamente, el navegador se recargará en 2 segundos...');
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const blacklistAccount = async () => {
			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'black_list_user/',
					{
						identification_number: clientData.user.identification_number
					},
					userData
				);

				if (response?.success === true) {
					setDialogMessage('El cliente fue agregado a la lista de clientes vetados por Krece.');
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		const resetPassword = async () => {
			try {
				const response = await unsafeAPI(
					'POST',
					process.env.REACT_APP_API_URL + 'change_user_password/',
					{
						email: clientData.user.email
					},
					userData
				);

				if (response?.success === true) {
					setDialogMessage(response?.status || 'Se reseteó la contraseña exitosamente.');
				} else {
					return response?.status?.length ? 'Error: ' + response.status : 'Ocurrio un problema intente mas tarde.';
				}
			} catch (err) {
				return 'Error: ' + err.message;
			}

			return true;
		};

		return (
			<div className="mx-auto w-full max-w-7xl p-4 md:p-[1.25rem]">
				{dialogMessage && (
					<Dialog onClose={() => setDialogMessage('')}>
						<p>{dialogMessage}</p>
					</Dialog>
				)}

				{confirmDialogProps && <ConfirmDialog {...confirmDialogProps} />}

				{editDialogProps && <ConfirmInputDialog {...editDialogProps} />}

				<form
					className="space-y-4"
					onSubmit={(e) => {
						e.preventDefault();
						searchClientData();
					}}
				>
					<div className="space-y-0 xl:text-center">
						<h2 className="text-2xl font-semibold text-neutral-100">Administrar Clientes</h2>
						<p className="text-sm text-neutral-400">Ingrese los datos para buscar información del cliente</p>
					</div>

					<div className="flex max-w-3xl flex-col gap-4 pt-3 md:flex-row md:gap-2 xl:mx-auto">
						<Select
							containerClassName="w-full"
							options={[
								{ label: 'Cédula o DNI', value: 'identification_number' },
								{ label: 'Email', value: 'email' },
								{ label: 'Teléfono', value: 'phone_number' },
								{ label: 'IMEI', value: 'imei_no' },
								{ label: 'Device ID', value: 'nuovopay_device_id' },
								{ label: 'Referencia', value: 'reference' }
							]}
							onSelectedChange={(value) => setIdentificationType(value)}
							placeholder="Buscar por"
							floatingLabel={'Tipo de documento'}
							defaultValue={identificationType}
						/>

						<Input
							containerClassName="w-full"
							name="cedula"
							placeholder={'Ingresar'}
							floatingLabel={'Valor a buscar'}
							value={identificationValue}
							onChange={(value) => setIdentificationValue(value)}
						/>

						<div className="ml-0 flex shrink-0 justify-end md:ml-4">
							<Button2
								Icon={<SearchIcon className="size-4" />}
								loading={loading}
								variant="primary"
								className="!h-12 w-fit"
								type="submit"
							>
								Buscar
							</Button2>
						</div>
					</div>
				</form>

				{clientData?.user ? (
					<>
						<div className="mt-6 space-y-4">
							<div>
								<h2 className="text-2xl font-semibold text-neutral-100">Información del cliente</h2>
								<p className="text-sm text-neutral-400">Datos personales y estado de cuenta</p>
							</div>

							<div className="grid grid-cols-2 gap-3 rounded-lg border border-neutral-800 bg-neutral-900/50 p-3">
								<div className="col-span-2 space-y-1.5 lg:col-span-1">
									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Nombre</span>
										<div className="flex items-center gap-2">
											<span className="text-sm text-neutral-100">{clientData.user.first_name}</span>
											<button
												onClick={() => {
													setEditDialogProps({
														title: 'Editar nombre',
														fields: [
															{
																type: 'input',
																name: 'new_value',

																label: 'Nuevo nombre',
																placeholder: 'Ingrese el nuevo nombre',
																defaultValue: clientData.user.first_name
															}
														],
														onConfirm: async (values) => {
															return changeName(values?.new_value || '');
														},
														onClose: () => setEditDialogProps(null)
													});
												}}
												className="rounded-full p-1 hover:bg-neutral-700/50"
											>
												<Pencil className="h-3.5 w-3.5 text-neutral-400 hover:text-neutral-300" />
											</button>
										</div>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Apellidos</span>
										<div className="flex items-center gap-2">
											<span className="text-sm text-neutral-100">{clientData.user.last_name}</span>
											<button
												onClick={() => {
													setEditDialogProps({
														title: 'Editar apellidos',
														fields: [
															{
																type: 'input',
																name: 'new_value',
																label: 'Nuevos apellidos',
																placeholder: 'Ingrese los nuevos apellidos',
																defaultValue: clientData.user.last_name
															}
														],
														onConfirm: async (values) => {
															return changeLastName(values?.new_value || '');
														},
														onClose: () => setEditDialogProps(null)
													});
												}}
												className="rounded-full p-1 hover:bg-neutral-700/50"
											>
												<Pencil className="h-3.5 w-3.5 text-neutral-400 hover:text-neutral-300" />
											</button>
										</div>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Cédula</span>
										<div className="flex items-center gap-2">
											<span className="text-sm text-neutral-100">{clientData.user.identification_number}</span>
											<button
												onClick={() => {
													setEditDialogProps({
														title: 'Editar Cédula o DNI',
														fields: [
															{
																type: 'input',
																name: 'new_value',
																label: 'Nueva Cédula o DNI',
																placeholder: 'Ingrese la nueva Cédula o DNI',
																defaultValue: clientData.user.identification_number
															}
														],
														onConfirm: async (values) => {
															return changeIdentificationNumber(values?.new_value || '');
														},
														onClose: () => setEditDialogProps(null)
													});
												}}
												className="rounded-full p-1 hover:bg-neutral-700/50"
											>
												<Pencil className="h-3.5 w-3.5 text-neutral-400 hover:text-neutral-300" />
											</button>
										</div>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Email</span>
										<div className="flex items-center gap-2">
											<span className="text-sm text-neutral-100">{clientData.user.email}</span>
											<button
												onClick={() => {
													setEditDialogProps({
														title: 'Editar Email',
														fields: [
															{
																type: 'input',
																name: 'new_value',
																label: 'Nuevo email',
																placeholder: 'Ingrese el nuevo email',
																defaultValue: clientData.user.email
															}
														],
														onConfirm: async (values) => {
															return changeEmail(values?.new_value || '');
														},
														onClose: () => setEditDialogProps(null)
													});
												}}
												className="rounded-full p-1 hover:bg-neutral-700/50"
											>
												<Pencil className="h-3.5 w-3.5 text-neutral-400 hover:text-neutral-300" />
											</button>
										</div>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Teléfono</span>
										<div className="flex items-center gap-2">
											<span className="text-sm text-neutral-100">{clientData.user.phone_number}</span>
											<button
												onClick={() => {
													setEditDialogProps({
														title: 'Editar Teléfono',
														fields: [
															{
																type: 'input',
																name: 'new_value',
																label: 'Nuevo teléfono',
																placeholder: 'Ingrese el nuevo teléfono',
																defaultValue: clientData.user.phone_number
															}
														],
														onConfirm: async (values) => {
															return changePhoneNumber(values?.new_value || '');
														},
														onClose: () => setEditDialogProps(null)
													});
												}}
												className="rounded-full p-1 hover:bg-neutral-700/50"
											>
												<Pencil className="h-3.5 w-3.5 text-neutral-400 hover:text-neutral-300" />
											</button>
										</div>
									</div>
								</div>

								<div className="col-span-2 -mt-[0.4rem] space-y-1.5 lg:col-span-1 lg:-mt-0">
									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Estado de cuenta</span>
										<div className="flex items-center gap-2">
											<span
												className={`rounded px-2 py-1 text-xs light:brightness-50 ${clientData.user.is_active ? 'bg-green-500/10 text-green-400' : 'bg-red-500/10 text-red-400'}`}
											>
												{clientData.user.is_active ? 'Activo' : 'Inactivo'}
											</span>
											<Button2
												className="!h-auto !w-fit !rounded !px-2 !py-1 !text-xs !font-normal"
												variant="secondary"
												onClick={() => {
													setConfirmDialogProps({
														title: 'Restablecer contraseña',
														message: '¿Está seguro que desea restablecer la contraseña?',
														onConfirm: async () => resetPassword(),
														onClose: () => setConfirmDialogProps(null)
													});
												}}
											>
												<span className="flex shrink-0 items-center gap-1">
													<Lock className="size-3.5 shrink-0" />
													<span className="overflow-hidden text-ellipsis whitespace-nowrap">Restablecer contraseña</span>
												</span>
											</Button2>
										</div>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Puntos</span>
										<div className="flex items-center gap-2">
											<span className="rounded bg-green-500/10 px-2 py-1 text-xs text-green-400 light:brightness-50">
												{clientData.user.points}
											</span>
											{clientData.user?.level && (
												<span
													className="rounded px-2 py-1 text-xs font-medium"
													style={{
														backgroundColor: 'black',
														color:
															clientData.user.level === 'Azul'
																? '#45C4D6'
																: clientData.user.level === 'Plata'
																	? '#C0C0C0'
																	: clientData.user.level === 'Oro'
																		? '#D4AF37'
																		: clientData.user.level === 'Platino'
																			? '#E6E8E6'
																			: ''
													}}
												>
													{clientData.user.level}
												</span>
											)}
										</div>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Estado de registro</span>
										<span className="text-sm text-neutral-100">{clientData.user.registration_status}</span>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Monto aprobado (3 meses)</span>
										<div className="flex items-center gap-2">
											<span className="text-sm text-neutral-100">${clientData.user.max_loan_approved_3months}</span>
											{ata && (
												<button
													onClick={() => {
														setEditDialogProps({
															title: 'Editar línea de crédito',
															fields: [
																{
																	type: 'input',
																	name: 'new_value',
																	label: 'Línea de crédito',
																	placeholder: 'Ingrese',
																	defaultValue: clientData.user.max_loan_approved_3months?.toString() || ''
																}
															],
															onConfirm: async (values) => {
																return changeCreditLine(values?.new_value?.toString() || '');
															},
															onClose: () => setEditDialogProps(null)
														});
													}}
													className="rounded-full p-1 hover:bg-neutral-700/50"
												>
													<Pencil className="h-3.5 w-3.5 text-neutral-400 hover:text-neutral-300" />
												</button>
											)}
										</div>
									</div>

									<div className="flex items-center justify-between rounded-md bg-neutral-800/30 px-3 py-2">
										<span className="text-sm text-neutral-400">Cuenta creada el</span>
										<span className="text-sm text-neutral-100">{clientData.user.created_at}</span>
									</div>
								</div>

								{ata && (
									<div className="col-span-2 flex items-center justify-end gap-3">
										<Button2
											Icon={<Ban className="size-4" />}
											onClick={() => {
												setConfirmDialogProps({
													title: 'Vetar cuenta',
													message: `¿Estás seguro de que deseas vetar esta cuenta?`,
													onConfirm: async () => await blacklistAccount(),
													onClose: () => setConfirmDialogProps(null)
												});
											}}
											className="w-fit bg-yellow-600 hover:bg-yellow-700"
											variant="warning"
										>
											Vetar cuenta
										</Button2>
										<Button2
											Icon={<Trash className="size-4" />}
											onClick={() => {
												setConfirmDialogProps({
													title: 'Eliminar cuenta',
													message: `¿Estás seguro de que deseas eliminar esta cuenta?`,
													onConfirm: async () => await deleteAccount(),
													onClose: () => setConfirmDialogProps(null)
												});
											}}
											className="w-fit bg-red-600 hover:bg-red-700"
											variant="destructive"
										>
											Eliminar cuenta
										</Button2>
									</div>
								)}
							</div>
						</div>

						<div className="mt-6 space-y-4">
							<div>
								<h2 className="text-2xl font-semibold text-neutral-100">Equipos</h2>
								<p className="text-sm text-neutral-400">Celulares, tablets y laptops</p>
							</div>
							{clientData?.devices?.filter((device) =>
								['phone', 'iphone', 'tablet', 'laptop'].includes(device?.device_type?.toLowerCase())
							)?.length > 0 ? (
								<div className="mt-6 grid gap-3">
									{clientData.devices
										.filter((device) => ['phone', 'iphone', 'tablet', 'laptop'].includes(device?.device_type?.toLowerCase()))
										.toSorted((a, b) => new Date(b.date_bought) - new Date(a.date_bought))
										.map((device) => (
											<details key={device.id} className="group rounded-lg border border-neutral-800 bg-neutral-900/50">
												<summary className="cursor-pointer list-none p-6">
													<div className="no-scrollbar flex items-center justify-between gap-6 overflow-x-auto">
														<div className="flex shrink-0 flex-wrap items-center gap-2">
															<div className="flex items-center gap-2">
																<svg
																	className="h-5 w-5 text-neutral-400 transition-transform group-open:rotate-90"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke="currentColor"
																>
																	<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
																</svg>
																<span
																	className={`rounded bg-neutral-500/10 px-2 py-1 text-xs text-neutral-500 dark:bg-neutral-500/20 dark:text-neutral-400`}
																>
																	{ucfirst(device.device_type)}
																</span>
																<span
																	className={`rounded px-2 py-1 text-xs ${!device.is_locked ? 'bg-green-500/10 text-green-700 dark:bg-green-500/20 dark:text-green-400' : 'bg-red-500/10 text-red-700 dark:bg-red-500/20 dark:text-red-400'}`}
																>
																	{!device.is_locked ? 'Activo' : 'Bloqueado'}
																</span>
																<h3 className="flex items-center gap-1 text-xl font-semibold text-neutral-100">
																	{device.device_commercial_name
																		? device.device_commercial_name
																		: `${device.device_manufacturer} ${device.device_model}`}
																	<button
																		onClick={() => {
																			setEditDialogProps({
																				title: 'Editar nombre comercial',
																				fields: [
																					{
																						type: 'input',
																						name: 'new_value',
																						label: 'Nombre comercial',
																						placeholder: 'Ingrese',
																						defaultValue:
																							device.device_commercial_name ||
																							`${device.device_manufacturer} ${device.device_model}`
																					}
																				],
																				onConfirm: async (values) => {
																					return changeCommercialName(device.id, values?.new_value?.toString() || '');
																				},
																				onClose: () => setEditDialogProps(null)
																			});
																		}}
																		className="hover:text-neutral-300"
																	>
																		<Pencil className="h-4 w-4" />
																	</button>
																</h3>
															</div>
															<div className="ml-4 flex items-center gap-1">
																<span
																	className={`rounded px-2 py-1 text-xs ${device.device_management_provider !== 'nuovo' ? 'bg-blue-500/10 text-blue-700 dark:bg-blue-500/20 dark:text-blue-400' : 'bg-orange-500/10 text-orange-700 dark:bg-orange-500/20 dark:text-orange-400'}`}
																>
																	{ucfirst(device.device_management_provider || '')}
																</span>
																<span className="text-neutral-400">/</span>
																<span
																	className={`rounded px-2 py-1 text-xs ${device.is_released ? 'bg-green-500/10 text-green-700 dark:bg-green-500/20 dark:text-green-400' : 'bg-yellow-500/10 text-yellow-700 dark:bg-yellow-500/20 dark:text-yellow-400'}`}
																>
																	{device.is_released ? 'Liberado' : 'Anclado'}
																</span>
															</div>
														</div>

														<div className="flex shrink-0 flex-wrap items-center justify-end gap-2">
															{device.is_stolen && (
																<span className="shrink-0 rounded bg-red-500/10 px-2 py-1 text-xs text-red-700 dark:bg-red-500/20 dark:text-red-400">
																	Robado
																</span>
															)}
															<span
																className={`rounded bg-neutral-500/10 px-2 py-1 text-xs text-neutral-700 dark:bg-neutral-500/20 dark:text-neutral-400`}
															>
																{device.seller_store}
															</span>
															<span className="text-sm text-neutral-400">
																{new Date(device.date_bought).toLocaleDateString()}
															</span>
														</div>
													</div>
												</summary>

												<div className="px-6 pb-6">
													<div className="mb-4 border-b border-neutral-800 pb-4">
														<div className="mt-4 space-y-1.5 text-sm md:space-y-4">
															<div className="grid grid-cols-1 gap-1.5 md:grid-cols-3 md:gap-4">
																<div className="rounded bg-neutral-800/30 p-3">
																	<p className="mb-1 text-xs font-medium text-neutral-300">IMEI</p>
																	<div className="flex items-center">
																		<p className="w-fit select-all rounded bg-neutral-925 px-1 font-mono text-neutral-100">
																			{device.device_imei_no}
																		</p>
																		<Copy
																			onClick={() => navigator.clipboard.writeText(device.device_imei_no)}
																			className="ml-1.5 size-4 cursor-pointer text-xs text-neutral-400 transition-colors duration-200 hover:text-neutral-200"
																		/>
																	</div>
																</div>
																<div className="rounded bg-neutral-800/30 p-3">
																	<p className="mb-1 text-xs font-medium text-neutral-300">ID de equipo</p>
																	<div className="flex items-center">
																		<p className="w-fit select-all rounded bg-neutral-925 px-1 font-mono text-neutral-100">
																			{device.nuovopay_device_id}
																		</p>
																		<Copy
																			onClick={() => navigator.clipboard.writeText(device.nuovopay_device_id)}
																			className="ml-1.5 size-4 cursor-pointer text-xs text-neutral-400 transition-colors duration-200 hover:text-neutral-200"
																		/>
																	</div>
																</div>
																<div className="rounded bg-neutral-800/30 p-3">
																	<p className="mb-1 text-xs font-medium text-neutral-300">ID</p>
																	<div className="flex items-center">
																		<p className="w-fit select-all rounded bg-neutral-925 px-1 font-mono text-neutral-100">
																			{device.id}
																		</p>
																		<Copy
																			onClick={() => navigator.clipboard.writeText(device.id)}
																			className="ml-1.5 size-4 cursor-pointer text-xs text-neutral-400 transition-colors duration-200 hover:text-neutral-200"
																		/>
																	</div>
																</div>
															</div>
															<div className="grid grid-cols-1 gap-1.5 md:grid-cols-2 md:gap-4">
																<div className="flex flex-col space-y-1.5 md:space-y-2">
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Fecha de compra</span>
																		<span className="text-neutral-100">{new Date(device.date_bought).toLocaleDateString()}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Precio</span>
																		<div className="flex items-center gap-1">
																			<span className="text-neutral-100">${device.device_price?.toFixed(2)}</span>
																			{ata && (
																				<button
																					onClick={() => {
																						setEditDialogProps({
																							title: 'Editar precio',
																							fields: [
																								{
																									type: 'input',
																									name: 'new_value',
																									label: 'Precio de venta',
																									placeholder: 'Ingrese el precio de venta',
																									defaultValue: device.device_price?.toFixed(2)
																								}
																							],
																							onConfirm: async (values) => {
																								return changeSalePrice(device.id, values?.new_value?.toString() || '');
																							},
																							onClose: () => setEditDialogProps(null)
																						});
																					}}
																					className="hover:text-neutral-300"
																				>
																					<Pencil className="h-4 w-4" />
																				</button>
																			)}
																		</div>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Monto financiado</span>
																		<span className="text-neutral-100">${device.amount_financed?.toFixed(2)}</span>
																	</div>
																</div>

																<div className="flex flex-col space-y-1.5 md:space-y-2">
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Tienda</span>
																		<span className="text-neutral-100">{device.seller_store}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Local</span>
																		<span className="text-neutral-100">{device.sub_store}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Email del comprador</span>
																		<span className="ml-4 truncate text-neutral-100">{device.user}</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="mt-6 flex flex-wrap items-center justify-between gap-4">
															<div className="mb-4">
																<h4 className="text-sm font-medium text-neutral-300">Estado del equipo</h4>
																<div className="mt-2 flex gap-2">
																	<Button2
																		loading={checkingDeviceStatus}
																		onClick={() => checkDeviceStatus(device.device_imei_no)}
																		className="rounded bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-blue-600"
																		Icon={<Search className="h-4 w-4" />}
																	>
																		Consultar estado
																	</Button2>
																	<Button2
																		onClick={() =>
																			setConfirmDialogProps({
																				title: 'Desbloquear equipo',
																				message: '¿Está seguro que desea desbloquear este equipo?',
																				onConfirm: async () => unlockDevice(device.id),
																				onClose: () => setConfirmDialogProps(null)
																			})
																		}
																		className="rounded bg-green-500 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-green-600"
																		Icon={<Unlock className="h-4 w-4" />}
																	>
																		Desbloquear
																	</Button2>
																</div>
															</div>

															<div className="mb-4">
																<h4 className="text-sm font-medium text-neutral-300">Estado de robo</h4>
																<div className="mt-2 flex gap-2">
																	<Button2
																		onClick={() =>
																			setConfirmDialogProps({
																				title: 'Reportar como robado',
																				message: '¿Está seguro que desea reportar este equipo como robado?',
																				onConfirm: async () => reportStolenDevice(device.id),
																				onClose: () => setConfirmDialogProps(null)
																			})
																		}
																		className="rounded bg-red-500 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-red-600"
																		Icon={<AlertTriangle className="h-4 w-4" />}
																	>
																		Reportar robo
																	</Button2>

																	<Button2
																		onClick={() => setDialogMessage('Aún falta implementar')}
																		className="rounded bg-green-500 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-green-600"
																		Icon={<CheckCircle className="h-4 w-4" />}
																	>
																		Marcar recuperado
																	</Button2>
																</div>
															</div>

															<div className="mb-4">
																<h4 className="text-sm font-medium text-neutral-300">Acciones críticas</h4>
																<div className="mt-2 flex gap-2">
																	<Button2
																		onClick={() =>
																			setConfirmDialogProps({
																				title: 'Liberar equipo',
																				message: '¿Está seguro que desea liberar este equipo?',
																				onConfirm: async () => freeDevice(device.id),
																				onClose: () => setConfirmDialogProps(null)
																			})
																		}
																		className="rounded bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-blue-600"
																		Icon={<Unlock className="h-4 w-4" />}
																	>
																		Liberar equipo
																	</Button2>
																	<Button2
																		onClick={() => setDialogMessage('Aún falta implementar')}
																		className="rounded bg-red-500 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-red-600"
																		Icon={<RefreshCcw className="h-4 w-4" />}
																	>
																		Reembolsar
																	</Button2>
																</div>
															</div>
														</div>
													</div>

													<div className="mb-4 border-b border-neutral-800 pb-4">
														<div className="mb-4 flex flex-col items-start justify-between gap-3 md:flex-row md:items-center md:gap-0">
															<div className="flex items-center gap-3">
																<h4 className="font-medium text-neutral-100">Cuotas</h4>
																<span className="rounded-md bg-neutral-800/50 px-2 py-1 text-base font-medium text-neutral-300">
																	{device.payments.length}
																</span>
															</div>{' '}
															<div className="flex w-full flex-col items-start gap-3 rounded-lg bg-neutral-800/50 px-4 py-2 text-sm sm:flex-row sm:items-center md:w-auto">
																<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																	<span className="text-neutral-400">Total pagado</span>
																	<span className="font-medium text-green-400">
																		${device.payments.reduce((sum, p) => sum + (p.amountPaid || 0), 0).toFixed(2)}
																	</span>
																</div>
																<div className="hidden h-6 w-px bg-neutral-700 sm:block"></div>
																<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																	<span className="text-neutral-400">Total a pagar</span>
																	<span className="font-medium text-neutral-100">
																		${device.payments.reduce((sum, p) => sum + p.actualAmount, 0).toFixed(2)}
																	</span>
																</div>
															</div>
														</div>

														<div className="space-y-3">
															{device.payments
																.toSorted((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
																.map((payment) => {
																	const isPastDue = new Date(payment.dueDate) < new Date() && !payment.paid;

																	return (
																		<div
																			key={payment.id}
																			className="relative rounded-lg border border-neutral-800 bg-neutral-900/30 p-4"
																		>
																			<div className="mb-3 flex flex-col justify-between md:flex-row md:items-start">
																				<div>
																					<div className="mb-1 flex items-center gap-2">
																						<span className="font-medium text-neutral-100">
																							{new Date(payment.dueDate).toLocaleDateString()}
																						</span>
																						<div className="flex gap-1.5">
																							{isPastDue && (
																								<span className="rounded bg-red-500/10 px-2 py-0.5 text-xs text-red-700 dark:bg-red-500/20 dark:text-red-400">
																									Vencida
																								</span>
																							)}
																							<span
																								className={`rounded px-2 py-0.5 text-xs ${
																									payment.paid
																										? 'bg-green-500/10 text-green-700 dark:bg-green-500/20 dark:text-green-400'
																										: 'bg-neutral-500/10 text-neutral-700 dark:bg-neutral-500/20 dark:text-neutral-400'
																								}`}
																							>
																								{payment.paid ? 'Pagado' : 'Pendiente'}
																							</span>
																						</div>
																					</div>
																					<div className="text-xs text-neutral-400">
																						ID: <span className="select-all font-mono">{payment.id}</span>
																						<Copy
																							onClick={() => navigator.clipboard.writeText(payment.id)}
																							className="ml-1 inline size-3 cursor-pointer text-xs text-neutral-300 transition-colors duration-200 hover:text-neutral-200"
																						/>
																						{payment.confirmationNumber && (
																							<>
																								{' '}
																								• Conf: <span className="select-all font-mono">{payment.confirmationNumber}</span>
																							</>
																						)}
																					</div>
																				</div>

																				<div className="mt-3 text-left md:mt-0 md:text-right">
																					<div className="mb-1 flex items-baseline gap-2">
																						<span className="text-sm text-neutral-400">Monto:</span>
																						<span className="font-medium text-neutral-100">
																							${payment.actualAmount?.toFixed(2)}
																						</span>
																					</div>
																					<div className="flex items-baseline gap-2 text-sm">
																						<span className="text-neutral-400">Pagado:</span>
																						<span className={payment.amountPaid ? 'text-green-400' : 'text-neutral-500'}>
																							${payment.amountPaid?.toFixed(2) || 0}
																						</span>
																						<span className="text-neutral-400">•</span>
																						<span className="text-neutral-400">Confirmado:</span>
																						<span className={payment.amount_confirmed ? 'text-blue-400' : 'text-neutral-500'}>
																							${payment.amount_confirmed?.toFixed(2) || 0}
																						</span>
																					</div>
																				</div>
																			</div>

																			<div className="flex items-center gap-2 text-xs">
																				<span className="text-neutral-400">Estado:</span>
																				<span className={`${payment.is_active ? 'text-blue-400' : 'text-neutral-500'}`}>
																					{payment.is_active ? 'Activo' : 'Inactivo'}
																				</span>
																				<span className="text-neutral-400">•</span>
																				<span className="text-neutral-400">Revisado por Krece:</span>
																				<span
																					className={`${payment.checked_by_internal_team ? 'text-green-400' : 'text-neutral-500'}`}
																				>
																					{payment.checked_by_internal_team ? 'Sí' : 'No'}
																				</span>
																			</div>
																		</div>
																	);
																})}
														</div>
													</div>
													<details className="group/transactions">
														<summary className="cursor-pointer list-none">
															<div className="mb-4 flex flex-col justify-between gap-3 md:flex-row md:items-center md:gap-0">
																<div className="flex items-center gap-2">
																	<svg
																		className="h-5 w-5 text-neutral-400 transition-transform group-open/transactions:rotate-90"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
																	</svg>
																	<div className="flex items-center gap-3">
																		<h4 className="font-medium text-neutral-100">Transacciones</h4>
																		<span className="rounded-md bg-neutral-800/50 px-2 py-1 text-base font-medium text-neutral-300">
																			{device.transactions.length}
																		</span>
																	</div>{' '}
																</div>
																<div className="flex w-full flex-col items-start gap-3 rounded-lg bg-neutral-800/50 px-4 py-2 text-sm sm:flex-row sm:items-center md:w-auto">
																	<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																		<span className="text-neutral-400">Total transacciones</span>
																		<span className="font-medium text-neutral-100">
																			${device.transactions.reduce((sum, t) => sum + t.amount, 0).toFixed(2)}
																		</span>
																	</div>
																</div>
															</div>
														</summary>

														<div className="space-y-3">
															{device.transactions
																.toSorted((a, b) => new Date(b.date) - new Date(a.date))
																.map((transaction) => (
																	<div
																		key={transaction.id}
																		className="relative rounded-lg border border-neutral-800 bg-neutral-900/30 p-4"
																	>
																		<div className="mb-3 flex flex-col justify-between md:flex-row md:items-start">
																			<div>
																				<div className="mb-1 flex items-center gap-2">
																					<span className="font-medium text-neutral-100">
																						{new Date(transaction.date).toLocaleDateString()}
																					</span>
																					<span
																						className={`rounded bg-blue-500/10 px-2 py-0.5 text-xs text-blue-700 dark:bg-blue-500/20 dark:text-blue-400`}
																					>
																						Pagado a {transaction.paid_to}
																					</span>
																				</div>
																				<div className="text-xs text-neutral-400">
																					ID: <span className="select-all font-mono">{transaction.id}</span>
																					<Copy
																						onClick={() => navigator.clipboard.writeText(transaction.id)}
																						className="ml-1 inline size-3 cursor-pointer text-xs text-neutral-300 transition-colors duration-200 hover:text-neutral-200"
																					/>
																					{transaction.related_payment && (
																						<>
																							{' '}
																							• Cuota: <span className="select-all font-mono">{transaction.related_payment}</span>
																						</>
																					)}
																				</div>
																			</div>

																			<div className="mt-3 text-left md:mt-0 md:text-right">
																				<div className="mb-1 flex items-baseline gap-2">
																					<span className="text-sm text-neutral-400">Monto:</span>
																					<span className="font-medium text-neutral-100">${transaction.amount?.toFixed(2)}</span>
																				</div>
																			</div>
																		</div>

																		<div className="flex items-center gap-2 text-xs">
																			<span className="text-neutral-400">Ref. Pago:</span>
																			<span className="select-all font-mono text-neutral-100">
																				{transaction.reference || '-'}
																			</span>
																			<span className="text-neutral-400">•</span>
																			<span className="text-neutral-400">Ref. Cash:</span>
																			<span className="select-all font-mono text-neutral-100">
																				{transaction.confirmation_number || '-'}
																			</span>
																		</div>

																		{ata && (
																			<button
																				onClick={() => {
																					setConfirmDialogProps({
																						title: 'Eliminar transacción',
																						message: `¿Estás seguro de que deseas eliminar esta transacción de ${transaction.amount?.toFixed(2)}?`,
																						onConfirm: async () => await deleteTransaction(transaction.id),
																						onClose: () => setConfirmDialogProps(null)
																					});
																				}}
																				className="absolute right-3 top-3 rounded-lg bg-red-500/20 p-1 hover:bg-red-500/30 md:bottom-3 md:top-auto"
																			>
																				<Trash className="size-5 text-red-500" />
																			</button>
																		)}
																	</div>
																))}
														</div>
													</details>
												</div>
											</details>
										))}
								</div>
							) : (
								<div className="rounded-lg border border-neutral-800 bg-neutral-900/50 p-6 text-center text-sm text-neutral-300">
									No se encontraron equipos
								</div>
							)}
						</div>

						<div className="mt-6 space-y-4">
							<div>
								<h2 className="text-2xl font-semibold text-neutral-100">Recargas</h2>
								<p className="text-sm text-neutral-400">Recargas de teléfonos</p>
							</div>
							{clientData?.devices?.filter((device) => ['recarga'].includes(device?.device_type?.toLowerCase()))?.length > 0 ? (
								<div className="mt-6 grid gap-3">
									{clientData.devices
										.filter((device) => ['recarga'].includes(device?.device_type?.toLowerCase()))
										.toSorted((a, b) => new Date(b.date_bought) - new Date(a.date_bought))
										.map((device) => (
											<details key={device.id} className="group rounded-lg border border-neutral-800 bg-neutral-900/50">
												<summary className="cursor-pointer list-none p-6">
													<div className="no-scrollbar flex items-center justify-between gap-6 overflow-x-auto">
														<div className="flex shrink-0 items-center gap-2">
															<div className="flex items-center gap-2">
																<svg
																	className="h-5 w-5 text-neutral-400 transition-transform group-open:rotate-90"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke="currentColor"
																>
																	<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
																</svg>
																<h3 className="flex items-center gap-3 text-xl font-semibold text-neutral-100">
																	<span>{device?.product_description || 'Recarga'}</span>
																	<span className="rounded-md bg-neutral-800/50 px-2 py-1 text-base font-medium text-neutral-300">
																		${device.device_price?.toFixed(2)}
																	</span>
																</h3>
															</div>
														</div>
														<div className="flex shrink-0 flex-wrap items-center justify-end gap-2">
															<span className="text-sm text-neutral-400">
																{new Date(device.date_bought).toLocaleDateString()}
															</span>
														</div>
													</div>
												</summary>

												<div className="px-6 pb-6">
													<div className="mb-4 border-b border-neutral-800 pb-4">
														<div className="mt-4 space-y-1.5 text-sm md:space-y-4">
															<div className="grid grid-cols-1 gap-1.5 md:grid-cols-2 md:gap-4">
																<div className="rounded bg-neutral-800/30 p-3">
																	<p className="mb-1 text-xs font-medium text-neutral-300">Confirmación</p>
																	<div className="flex items-center">
																		<p className="w-fit select-all rounded bg-neutral-925 px-1 font-mono text-neutral-100">
																			{device.device_confirmation_id}
																		</p>
																		<Copy
																			onClick={() => navigator.clipboard.writeText(device.device_confirmation_id)}
																			className="ml-1.5 size-4 cursor-pointer text-xs text-neutral-400 transition-colors duration-200 hover:text-neutral-200"
																		/>
																	</div>
																</div>
																<div className="rounded bg-neutral-800/30 p-3">
																	<p className="mb-1 text-xs font-medium text-neutral-300">ID</p>
																	<div className="flex items-center">
																		<p className="w-fit select-all rounded bg-neutral-925 px-1 font-mono text-neutral-100">
																			{device.id}
																		</p>
																		<Copy
																			onClick={() => navigator.clipboard.writeText(device.id)}
																			className="ml-1.5 size-4 cursor-pointer text-xs text-neutral-400 transition-colors duration-200 hover:text-neutral-200"
																		/>
																	</div>
																</div>
															</div>
															<div className="grid grid-cols-1 gap-1.5 md:grid-cols-2 md:gap-4">
																<div className="flex flex-col space-y-1.5 md:space-y-2">
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Fecha de compra</span>
																		<span className="text-neutral-100">{new Date(device.date_bought).toLocaleDateString()}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Precio</span>
																		<span className="text-neutral-100">${device.device_price?.toFixed(2)}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Monto financiado</span>
																		<span className="text-neutral-100">${device.amount_financed?.toFixed(2)}</span>
																	</div>
																</div>

																<div className="flex flex-col space-y-1.5 md:space-y-2">
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Teléfono recargado</span>
																		<span className="text-neutral-100">{device.phone_number}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Operador</span>
																		<span className="text-neutral-100">{ucfirst(device.operator)}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Email del comprador</span>
																		<span className="ml-4 truncate text-neutral-100">{device.user}</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="mt-4 flex flex-col justify-end gap-2 sm:flex-row">
															<Button2
																onClick={() => setDialogMessage('Aún falta implementar')}
																className="w-fit rounded bg-red-500 px-4 py-2.5 text-sm font-medium text-white transition-colors hover:bg-red-600"
															>
																Reembolsar
															</Button2>
														</div>
													</div>

													<div className="mb-4 border-b border-neutral-800 pb-4">
														<div className="mb-4 flex flex-col items-start justify-between gap-3 md:flex-row md:items-center md:gap-0">
															<div className="flex items-center gap-3">
																<h4 className="font-medium text-neutral-100">Cuotas</h4>
																<span className="rounded-md bg-neutral-800/50 px-2 py-1 text-base font-medium text-neutral-300">
																	{device.payments.length}
																</span>
															</div>
															<div className="flex w-full flex-col items-start gap-3 rounded-lg bg-neutral-800/50 px-4 py-2 text-sm sm:flex-row sm:items-center md:w-auto">
																<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																	<span className="text-neutral-400">Total pagado</span>
																	<span className="font-medium text-green-400">
																		${device.payments.reduce((sum, p) => sum + (p.amountPaid || 0), 0).toFixed(2)}
																	</span>
																</div>
																<div className="hidden h-6 w-px bg-neutral-700 sm:block"></div>
																<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																	<span className="text-neutral-400">Total a pagar</span>
																	<span className="font-medium text-neutral-100">
																		${device.payments.reduce((sum, p) => sum + p.actualAmount, 0).toFixed(2)}
																	</span>
																</div>
															</div>
														</div>

														<div className="space-y-3">
															{device.payments
																.toSorted((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
																.map((payment) => {
																	const isPastDue = new Date(payment.dueDate) < new Date() && !payment.paid;

																	return (
																		<div
																			key={payment.id}
																			className="relative rounded-lg border border-neutral-800 bg-neutral-900/30 p-4"
																		>
																			<div className="mb-3 flex flex-col justify-between md:flex-row md:items-start">
																				<div>
																					<div className="mb-1 flex items-center gap-2">
																						<span className="font-medium text-neutral-100">
																							{new Date(payment.dueDate).toLocaleDateString()}
																						</span>
																						<div className="flex gap-1.5">
																							{isPastDue && (
																								<span className="rounded bg-red-500/10 px-2 py-0.5 text-xs text-red-700 dark:bg-red-500/20 dark:text-red-400">
																									Vencida
																								</span>
																							)}
																							<span
																								className={`rounded px-2 py-0.5 text-xs ${
																									payment.paid
																										? 'bg-green-500/10 text-green-700 dark:bg-green-500/20 dark:text-green-400'
																										: 'bg-neutral-500/10 text-neutral-700 dark:bg-neutral-500/20 dark:text-neutral-400'
																								}`}
																							>
																								{payment.paid ? 'Pagado' : 'Pendiente'}
																							</span>
																						</div>
																					</div>
																					<div className="text-xs text-neutral-400">
																						ID: <span className="select-all font-mono">{payment.id}</span>
																						<Copy
																							onClick={() => navigator.clipboard.writeText(payment.id)}
																							className="ml-1 inline size-3 cursor-pointer text-xs text-neutral-300 transition-colors duration-200 hover:text-neutral-200"
																						/>
																						{payment.confirmationNumber && (
																							<>
																								{' '}
																								• Conf: <span className="select-all font-mono">{payment.confirmationNumber}</span>
																							</>
																						)}
																					</div>
																				</div>

																				<div className="mt-3 text-left md:mt-0 md:text-right">
																					<div className="mb-1 flex items-baseline gap-2">
																						<span className="text-sm text-neutral-400">Monto:</span>
																						<span className="font-medium text-neutral-100">
																							${payment.actualAmount?.toFixed(2)}
																						</span>
																					</div>
																					<div className="flex items-baseline gap-2 text-sm">
																						<span className="text-neutral-400">Pagado:</span>
																						<span className={payment.amountPaid ? 'text-green-400' : 'text-neutral-500'}>
																							${payment.amountPaid?.toFixed(2) || 0}
																						</span>
																						<span className="text-neutral-400">•</span>
																						<span className="text-neutral-400">Confirmado:</span>
																						<span className={payment.amount_confirmed ? 'text-blue-400' : 'text-neutral-500'}>
																							${payment.amount_confirmed?.toFixed(2) || 0}
																						</span>
																					</div>
																				</div>
																			</div>

																			<div className="flex items-center gap-2 text-xs">
																				<span className="text-neutral-400">Estado:</span>
																				<span className={`${payment.is_active ? 'text-blue-400' : 'text-neutral-500'}`}>
																					{payment.is_active ? 'Activo' : 'Inactivo'}
																				</span>
																				<span className="text-neutral-400">•</span>
																				<span className="text-neutral-400">Revisado por Krece:</span>
																				<span
																					className={`${payment.checked_by_internal_team ? 'text-green-400' : 'text-neutral-500'}`}
																				>
																					{payment.checked_by_internal_team ? 'Sí' : 'No'}
																				</span>
																			</div>
																		</div>
																	);
																})}
														</div>
													</div>
													<details className="group/transactions">
														<summary className="cursor-pointer list-none">
															<div className="mb-4 flex flex-col justify-between gap-3 md:flex-row md:items-center md:gap-0">
																<div className="flex items-center gap-2">
																	<svg
																		className="h-5 w-5 text-neutral-400 transition-transform group-open/transactions:rotate-90"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
																	</svg>
																	<div className="flex items-center gap-3">
																		<h4 className="font-medium text-neutral-100">Transacciones</h4>
																		<span className="rounded-md bg-neutral-800/50 px-2 py-1 text-base font-medium text-neutral-300">
																			{device.transactions.length}
																		</span>
																	</div>{' '}
																</div>
																<div className="flex w-full flex-col items-start gap-3 rounded-lg bg-neutral-800/50 px-4 py-2 text-sm sm:flex-row sm:items-center md:w-auto">
																	<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																		<span className="text-neutral-400">Total transacciones</span>
																		<span className="font-medium text-neutral-100">
																			${device.transactions.reduce((sum, t) => sum + t.amount, 0).toFixed(2)}
																		</span>
																	</div>
																</div>
															</div>
														</summary>

														<div className="space-y-3">
															{device.transactions
																.toSorted((a, b) => new Date(b.date) - new Date(a.date))
																.map((transaction) => (
																	<div
																		key={transaction.id}
																		className="relative rounded-lg border border-neutral-800 bg-neutral-900/30 p-4"
																	>
																		<div className="mb-3 flex flex-col justify-between md:flex-row md:items-start">
																			<div>
																				<div className="mb-1 flex items-center gap-2">
																					<span className="font-medium text-neutral-100">
																						{new Date(transaction.date).toLocaleDateString()}
																					</span>
																					<span
																						className={`rounded bg-blue-500/10 px-2 py-0.5 text-xs text-blue-700 dark:bg-blue-500/20 dark:text-blue-400`}
																					>
																						Pagado a {transaction.paid_to}
																					</span>
																				</div>
																				<div className="text-xs text-neutral-400">
																					ID: <span className="select-all font-mono">{transaction.id}</span>
																					<Copy
																						onClick={() => navigator.clipboard.writeText(transaction.id)}
																						className="ml-1 inline size-3 cursor-pointer text-xs text-neutral-300 transition-colors duration-200 hover:text-neutral-200"
																					/>
																					{transaction.related_payment && (
																						<>
																							{' '}
																							• Cuota: <span className="select-all font-mono">{transaction.related_payment}</span>
																						</>
																					)}
																				</div>
																			</div>

																			<div className="mt-3 text-left md:mt-0 md:text-right">
																				<div className="mb-1 flex items-baseline gap-2">
																					<span className="text-sm text-neutral-400">Monto:</span>
																					<span className="font-medium text-neutral-100">${transaction.amount?.toFixed(2)}</span>
																				</div>
																			</div>
																		</div>

																		<div className="flex items-center gap-2 text-xs">
																			<span className="text-neutral-400">Ref. Pago:</span>
																			<span className="select-all font-mono text-neutral-100">
																				{transaction.reference || '-'}
																			</span>
																			<span className="text-neutral-400">•</span>
																			<span className="text-neutral-400">Ref. Cash:</span>
																			<span className="select-all font-mono text-neutral-100">
																				{transaction.confirmation_number || '-'}
																			</span>
																		</div>

																		{ata && (
																			<button
																				onClick={() => {
																					setConfirmDialogProps({
																						title: 'Eliminar transacción',
																						message: `¿Estás seguro de que deseas eliminar esta transacción de ${transaction.amount?.toFixed(2)}?`,
																						onConfirm: async () => await deleteTransaction(transaction.id),
																						onClose: () => setConfirmDialogProps(null)
																					});
																				}}
																				className="absolute right-3 top-3 rounded-lg bg-red-500/20 p-1 hover:bg-red-500/30 md:bottom-3 md:top-auto"
																			>
																				<Trash className="size-5 text-red-500" />
																			</button>
																		)}
																	</div>
																))}
														</div>
													</details>
												</div>
											</details>
										))}
								</div>
							) : (
								<div className="rounded-lg border border-neutral-800 bg-neutral-900/50 p-6 text-center text-sm text-neutral-300">
									No se encontraron recargas
								</div>
							)}
						</div>

						<div className="mt-6 space-y-4">
							<div>
								<h2 className="text-2xl font-semibold text-neutral-100">Otros</h2>
								<p className="text-sm text-neutral-400">Otros productos</p>
							</div>
							{clientData?.devices?.filter((device) => ['other'].includes(device?.device_type?.toLowerCase()))?.length > 0 ? (
								<div className="mt-6 grid gap-3">
									{clientData.devices
										.filter((device) => ['other'].includes(device?.device_type?.toLowerCase()))
										.toSorted((a, b) => new Date(b.date_bought) - new Date(a.date_bought))
										.map((device) => (
											<details key={device.id} className="group rounded-lg border border-neutral-800 bg-neutral-900/50">
												<summary className="cursor-pointer list-none p-6">
													<div className="no-scrollbar flex items-center justify-between gap-6 overflow-x-auto">
														<div className="flex shrink-0 items-center gap-2">
															<div className="flex items-center gap-2">
																<svg
																	className="h-5 w-5 text-neutral-400 transition-transform group-open:rotate-90"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke="currentColor"
																>
																	<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
																</svg>
																<h3 className="flex items-center gap-1 text-xl font-semibold text-neutral-100">
																	<span>{device.device_commercial_name}</span>
																	<button
																		onClick={() => {
																			setEditDialogProps({
																				title: 'Editar nombre comercial',
																				fields: [
																					{
																						type: 'input',
																						name: 'new_value',
																						label: 'Nombre comercial',
																						placeholder: 'Ingrese',
																						defaultValue:
																							device.device_commercial_name ||
																							`${device.device_manufacturer} ${device.device_model}`
																					}
																				],
																				onConfirm: async (values) => {
																					return changeCommercialName(device.id, values?.new_value?.toString() || '');
																				},
																				onClose: () => setEditDialogProps(null)
																			});
																		}}
																		className="hover:text-neutral-300"
																	>
																		<Pencil className="h-4 w-4" />
																	</button>
																</h3>
															</div>
														</div>
														<div className="flex shrink-0 flex-wrap items-center justify-end gap-2">
															<span
																className={`rounded bg-neutral-500/10 px-2 py-1 text-xs text-neutral-700 dark:bg-neutral-500/20 dark:text-neutral-400`}
															>
																{device.seller_store}
															</span>
															<span className="text-sm text-neutral-400">
																{new Date(device.date_bought).toLocaleDateString()}
															</span>
														</div>
													</div>
												</summary>

												<div className="px-6 pb-6">
													<div className="mb-4 border-b border-neutral-800 pb-4">
														<div className="mt-4 space-y-1.5 text-sm md:space-y-4">
															<div className="grid grid-cols-1 gap-1.5 md:grid-cols-2 md:gap-4">
																<div className="rounded bg-neutral-800/30 p-3">
																	<p className="mb-1 text-xs font-medium text-neutral-300">ID de producto</p>
																	<div className="flex items-center">
																		<p className="w-fit select-all rounded bg-neutral-925 px-1 font-mono text-neutral-100">
																			{device.nuovopay_device_id}
																		</p>
																		<Copy
																			onClick={() => navigator.clipboard.writeText(device.nuovopay_device_id)}
																			className="ml-1.5 size-4 cursor-pointer text-xs text-neutral-400 transition-colors duration-200 hover:text-neutral-200"
																		/>
																	</div>
																</div>
																<div className="rounded bg-neutral-800/30 p-3">
																	<p className="mb-1 text-xs font-medium text-neutral-300">ID</p>
																	<div className="flex items-center">
																		<p className="w-fit select-all rounded bg-neutral-925 px-1 font-mono text-neutral-100">
																			{device.id}
																		</p>
																		<Copy
																			onClick={() => navigator.clipboard.writeText(device.id)}
																			className="ml-1.5 size-4 cursor-pointer text-xs text-neutral-400 transition-colors duration-200 hover:text-neutral-200"
																		/>
																	</div>
																</div>
															</div>
															<div className="grid grid-cols-1 gap-1.5 md:grid-cols-2 md:gap-4">
																<div className="flex flex-col space-y-1.5 md:space-y-2">
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Fecha de compra</span>
																		<span className="text-neutral-100">{new Date(device.date_bought).toLocaleDateString()}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Precio</span>
																		<div className="flex items-center gap-1">
																			<span className="text-neutral-100">${device.device_price?.toFixed(2)}</span>
																			{ata && (
																				<button
																					onClick={() => {
																						setEditDialogProps({
																							title: 'Editar precio',
																							fields: [
																								{
																									type: 'input',
																									name: 'new_value',
																									label: 'Precio de venta',
																									placeholder: 'Ingrese el precio de venta',
																									defaultValue: device.device_price?.toFixed(2)
																								}
																							],
																							onConfirm: async (values) => {
																								return changeSalePrice(device.id, values?.new_value?.toString() || '');
																							},
																							onClose: () => setEditDialogProps(null)
																						});
																					}}
																					className="hover:text-neutral-300"
																				>
																					<Pencil className="h-4 w-4" />
																				</button>
																			)}
																		</div>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Monto financiado</span>
																		<span className="text-neutral-100">${device.amount_financed?.toFixed(2)}</span>
																	</div>
																</div>

																<div className="flex flex-col space-y-1.5 md:space-y-2">
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Tienda</span>
																		<span className="text-neutral-100">{device.seller_store}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Local</span>
																		<span className="text-neutral-100">{device.sub_store}</span>
																	</div>
																	<div className="flex justify-between rounded-md bg-neutral-800/30 px-3 py-2">
																		<span className="text-neutral-300">Email del comprador</span>
																		<span className="ml-4 truncate text-neutral-100">{device.user}</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="mt-4 flex flex-col justify-end gap-2 sm:flex-row">
															<Button2
																onClick={() => setDialogMessage('Aún falta implementar')}
																className="w-fit rounded bg-red-500 px-4 py-2.5 text-sm font-medium text-white transition-colors hover:bg-red-600"
															>
																Reembolsar
															</Button2>
														</div>
													</div>

													<div className="mb-4 border-b border-neutral-800 pb-4">
														<div className="mb-4 flex flex-col items-start justify-between gap-3 md:flex-row md:items-center md:gap-0">
															<div className="flex items-center gap-3">
																<h4 className="font-medium text-neutral-100">Cuotas</h4>
																<span className="rounded-md bg-neutral-800/50 px-2 py-1 text-base font-medium text-neutral-300">
																	{device.payments.length}
																</span>
															</div>{' '}
															<div className="flex w-full flex-col items-start gap-3 rounded-lg bg-neutral-800/50 px-4 py-2 text-sm sm:flex-row sm:items-center md:w-auto">
																<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																	<span className="text-neutral-400">Total pagado</span>
																	<span className="font-medium text-green-400">
																		${device.payments.reduce((sum, p) => sum + (p.amountPaid || 0), 0).toFixed(2)}
																	</span>
																</div>
																<div className="hidden h-6 w-px bg-neutral-700 sm:block"></div>
																<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																	<span className="text-neutral-400">Total a pagar</span>
																	<span className="font-medium text-neutral-100">
																		${device.payments.reduce((sum, p) => sum + p.actualAmount, 0).toFixed(2)}
																	</span>
																</div>
															</div>
														</div>

														<div className="space-y-3">
															{device.payments
																.toSorted((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
																.map((payment) => {
																	const isPastDue = new Date(payment.dueDate) < new Date() && !payment.paid;

																	return (
																		<div
																			key={payment.id}
																			className="relative rounded-lg border border-neutral-800 bg-neutral-900/30 p-4"
																		>
																			<div className="mb-3 flex flex-col justify-between md:flex-row md:items-start">
																				<div>
																					<div className="mb-1 flex items-center gap-2">
																						<span className="font-medium text-neutral-100">
																							{new Date(payment.dueDate).toLocaleDateString()}
																						</span>
																						<div className="flex gap-1.5">
																							{isPastDue && (
																								<span className="rounded bg-red-500/10 px-2 py-0.5 text-xs text-red-700 dark:bg-red-500/20 dark:text-red-400">
																									Vencida
																								</span>
																							)}
																							<span
																								className={`rounded px-2 py-0.5 text-xs ${
																									payment.paid
																										? 'bg-green-500/10 text-green-700 dark:bg-green-500/20 dark:text-green-400'
																										: 'bg-neutral-500/10 text-neutral-700 dark:bg-neutral-500/20 dark:text-neutral-400'
																								}`}
																							>
																								{payment.paid ? 'Pagado' : 'Pendiente'}
																							</span>
																						</div>
																					</div>
																					<div className="text-xs text-neutral-400">
																						ID: <span className="select-all font-mono">{payment.id}</span>
																						<Copy
																							onClick={() => navigator.clipboard.writeText(payment.id)}
																							className="ml-1 inline size-3 cursor-pointer text-xs text-neutral-300 transition-colors duration-200 hover:text-neutral-200"
																						/>
																						{payment.confirmationNumber && (
																							<>
																								{' '}
																								• Conf: <span className="select-all font-mono">{payment.confirmationNumber}</span>
																							</>
																						)}
																					</div>
																				</div>

																				<div className="mt-3 text-left md:mt-0 md:text-right">
																					<div className="mb-1 flex items-baseline gap-2">
																						<span className="text-sm text-neutral-400">Monto:</span>
																						<span className="font-medium text-neutral-100">
																							${payment.actualAmount?.toFixed(2)}
																						</span>
																					</div>
																					<div className="flex items-baseline gap-2 text-sm">
																						<span className="text-neutral-400">Pagado:</span>
																						<span className={payment.amountPaid ? 'text-green-400' : 'text-neutral-500'}>
																							${payment.amountPaid?.toFixed(2) || 0}
																						</span>
																						<span className="text-neutral-400">•</span>
																						<span className="text-neutral-400">Confirmado:</span>
																						<span className={payment.amount_confirmed ? 'text-blue-400' : 'text-neutral-500'}>
																							${payment.amount_confirmed?.toFixed(2) || 0}
																						</span>
																					</div>
																				</div>
																			</div>

																			<div className="flex items-center gap-2 text-xs">
																				<span className="text-neutral-400">Estado:</span>
																				<span className={`${payment.is_active ? 'text-blue-400' : 'text-neutral-500'}`}>
																					{payment.is_active ? 'Activo' : 'Inactivo'}
																				</span>
																				<span className="text-neutral-400">•</span>
																				<span className="text-neutral-400">Revisado por Krece:</span>
																				<span
																					className={`${payment.checked_by_internal_team ? 'text-green-400' : 'text-neutral-500'}`}
																				>
																					{payment.checked_by_internal_team ? 'Sí' : 'No'}
																				</span>
																			</div>
																		</div>
																	);
																})}
														</div>
													</div>
													<details className="group/transactions">
														<summary className="cursor-pointer list-none">
															<div className="mb-4 flex flex-col justify-between gap-3 md:flex-row md:items-center md:gap-0">
																<div className="flex items-center gap-2">
																	<svg
																		className="h-5 w-5 text-neutral-400 transition-transform group-open/transactions:rotate-90"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																	>
																		<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
																	</svg>
																	<div className="flex items-center gap-3">
																		<h4 className="font-medium text-neutral-100">Transacciones</h4>
																		<span className="rounded-md bg-neutral-800/50 px-2 py-1 text-base font-medium text-neutral-300">
																			{device.transactions.length}
																		</span>
																	</div>{' '}
																</div>
																<div className="flex w-full flex-col items-start gap-3 rounded-lg bg-neutral-800/50 px-4 py-2 text-sm sm:flex-row sm:items-center md:w-auto">
																	<div className="flex w-full items-center justify-between gap-2 sm:w-auto sm:justify-start">
																		<span className="text-neutral-400">Total transacciones</span>
																		<span className="font-medium text-neutral-100">
																			${device.transactions.reduce((sum, t) => sum + t.amount, 0).toFixed(2)}
																		</span>
																	</div>
																</div>
															</div>
														</summary>

														<div className="space-y-3">
															{device.transactions
																.toSorted((a, b) => new Date(b.date) - new Date(a.date))
																.map((transaction) => (
																	<div
																		key={transaction.id}
																		className="relative rounded-lg border border-neutral-800 bg-neutral-900/30 p-4"
																	>
																		<div className="mb-3 flex flex-col justify-between md:flex-row md:items-start">
																			<div>
																				<div className="mb-1 flex items-center gap-2">
																					<span className="font-medium text-neutral-100">
																						{new Date(transaction.date).toLocaleDateString()}
																					</span>
																					<span
																						className={`rounded bg-blue-500/10 px-2 py-0.5 text-xs text-blue-700 dark:bg-blue-500/20 dark:text-blue-400`}
																					>
																						Pagado a {transaction.paid_to}
																					</span>
																				</div>
																				<div className="text-xs text-neutral-400">
																					ID: <span className="select-all font-mono">{transaction.id}</span>
																					<Copy
																						onClick={() => navigator.clipboard.writeText(transaction.id)}
																						className="ml-1 inline size-3 cursor-pointer text-xs text-neutral-300 transition-colors duration-200 hover:text-neutral-200"
																					/>
																					{transaction.related_payment && (
																						<>
																							{' '}
																							• Cuota: <span className="select-all font-mono">{transaction.related_payment}</span>
																						</>
																					)}
																				</div>
																			</div>

																			<div className="mt-3 text-left md:mt-0 md:text-right">
																				<div className="mb-1 flex items-baseline gap-2">
																					<span className="text-sm text-neutral-400">Monto:</span>
																					<span className="font-medium text-neutral-100">${transaction.amount?.toFixed(2)}</span>
																				</div>
																			</div>
																		</div>

																		<div className="flex items-center gap-2 text-xs">
																			<span className="text-neutral-400">Ref. Pago:</span>
																			<span className="select-all font-mono text-neutral-100">
																				{transaction.reference || '-'}
																			</span>
																			<span className="text-neutral-400">•</span>
																			<span className="text-neutral-400">Ref. Cash:</span>
																			<span className="select-all font-mono text-neutral-100">
																				{transaction.confirmation_number || '-'}
																			</span>
																		</div>

																		{ata && (
																			<button
																				onClick={() => {
																					setConfirmDialogProps({
																						title: 'Eliminar transacción',
																						message: `¿Estás seguro de que deseas eliminar esta transacción de ${transaction.amount?.toFixed(2)}?`,
																						onConfirm: async () => await deleteTransaction(transaction.id),
																						onClose: () => setConfirmDialogProps(null)
																					});
																				}}
																				className="absolute right-3 top-3 rounded-lg bg-red-500/20 p-1 hover:bg-red-500/30 md:bottom-3 md:top-auto"
																			>
																				<Trash className="size-5 text-red-500" />
																			</button>
																		)}
																	</div>
																))}
														</div>
													</details>
												</div>
											</details>
										))}
								</div>
							) : (
								<div className="rounded-lg border border-neutral-800 bg-neutral-900/50 p-6 text-center text-sm text-neutral-300">
									No se encontraron otros productos o servicios
								</div>
							)}
						</div>

						{unrelatedTransactions.length > 0 && (
							<>
								<div className="mb-4 mt-6 space-y-4">
									<div>
										<h2 className="text-2xl font-semibold text-neutral-100">Transacciones sin correspondiente</h2>
										<p className="text-sm text-neutral-400">
											Estas transacciones deben ser por algún error, no están relacionadas con ninguna cuota de todo lo anterior
										</p>
									</div>
								</div>
								{unrelatedTransactions.map((transaction) => (
									<div key={transaction.id} className="relative rounded-lg border border-neutral-800 bg-neutral-900/30 p-4">
										<div className="mb-3 flex flex-col justify-between md:flex-row md:items-start">
											<div>
												<div className="mb-1 flex items-center gap-2">
													<span className="font-medium text-neutral-100">{new Date(transaction.date).toLocaleDateString()}</span>
													<span
														className={`rounded bg-blue-500/10 px-2 py-0.5 text-xs text-blue-700 dark:bg-blue-500/20 dark:text-blue-400`}
													>
														Pagado a {transaction.paid_to}
													</span>
												</div>
												<div className="text-xs text-neutral-400">
													ID: <span className="select-all font-mono">{transaction.id}</span>
													<Copy
														onClick={() => navigator.clipboard.writeText(transaction.id)}
														className="ml-1 inline size-3 cursor-pointer text-xs text-neutral-300 transition-colors duration-200 hover:text-neutral-200"
													/>
													{transaction.related_payment && (
														<>
															{' '}
															• Cuota: <span className="select-all font-mono">{transaction.related_payment}</span>
														</>
													)}
												</div>
											</div>

											<div className="mt-3 text-left md:mt-0 md:text-right">
												<div className="mb-1 flex items-baseline gap-2">
													<span className="text-sm text-neutral-400">Monto:</span>
													<span className="font-medium text-neutral-100">${transaction.amount?.toFixed(2)}</span>
												</div>
											</div>
										</div>

										<div className="flex items-center gap-2 text-xs">
											<span className="text-neutral-400">Ref. Pago:</span>
											<span className="select-all font-mono text-neutral-100">{transaction.reference || '-'}</span>
											<span className="text-neutral-400">•</span>
											<span className="text-neutral-400">Ref. Cash:</span>
											<span className="select-all font-mono text-neutral-100">{transaction.confirmation_number || '-'}</span>
										</div>

										{ata && (
											<button
												onClick={() => {
													setConfirmDialogProps({
														title: 'Eliminar transacción',
														message: `¿Estás seguro de que deseas eliminar esta transacción de ${transaction.amount?.toFixed(2)}?`,
														onConfirm: async () => await deleteTransaction(transaction.id),
														onClose: () => setConfirmDialogProps(null)
													});
												}}
												className="absolute right-3 top-3 rounded-lg bg-red-500/20 p-1 hover:bg-red-500/30 md:bottom-3 md:top-auto"
											>
												<Trash className="size-5 text-red-500" />
											</button>
										)}
									</div>
								))}
							</>
						)}
					</>
				) : clientData === false ? (
					<div className="mt-6 rounded-lg border border-neutral-800 bg-neutral-900/50 p-6 text-center text-sm text-neutral-300">
						No se encontró información del cliente
					</div>
				) : null}
			</div>
		);
	}

	function VerifyPayments() {
		const banks = GetBanks(userData?.country);
		const [tableKey, setTableKey] = useState(0);
		const [tableKey2, setTableKey2] = useState(100);

		const [selectedPayment, setSelectedPayment] = useState(null);
		const formValid =
			selectedPayment?.date &&
			selectedPayment?.amount &&
			selectedPayment?.bank_id &&
			selectedPayment?.bank_account_number &&
			selectedPayment?.reference &&
			selectedPayment?.unverified_payment_id;

		const [submitForm, setSubmitForm] = useState(false);
		const { error, loading, setPayload, reset, fetched } = useFetch(
			'https://api.krece.app/payments/processunverifiedpayment/',
			'PUT',
			submitForm
		);

		useEffect(() => {
			if (!selectedPayment) reset();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selectedPayment]);

		useEffect(() => {
			if (fetched && !error) {
				setSelectedPayment(null);
				reset();
				setTableKey((prev) => prev + 1);
				setTableKey2((prev) => prev + 1);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [fetched]);

		return (
			<div className="mx-auto w-full p-4 md:p-[1.25rem]">
				{selectedPayment && (
					<Dialog closable={false} title="Información de Pago (Cliente)" open={true}>
						<form className="w-[400px] max-w-full space-y-6" onSubmit={(e) => e.preventDefault()}>
							<div className="flex flex-col gap-2">
								<div className="flex gap-2">
									<Input
										inputClassName="font-semibold"
										Icon={<span className="text-neutral-500">Fecha: </span>}
										value={selectedPayment?.date || ''}
										readonly={true}
										containerClassName="w-full"
									/>
									<Input
										inputClassName="font-semibold"
										Icon={<span className="text-neutral-500">Monto: </span>}
										value={selectedPayment?.amount?.toString() || ''}
										onChange={(value) =>
											setSelectedPayment({ ...selectedPayment, amount: isNaN(parseFloat(value)) ? value : parseFloat(value) })
										}
										allowedChars="numeric"
										containerClassName="w-full"
									/>
								</div>
								<Input
									Icon={<span className="text-neutral-500">Referencia: </span>}
									value={selectedPayment?.customer_reference || ''}
									readonly={true}
									containerClassName="w-full"
								/>
								<Input
									Icon={<span className="text-neutral-500">Num. de cuenta: </span>}
									value={selectedPayment?.customer_bank_account_number || ''}
									readonly={true}
									containerClassName="w-full"
								/>

								<h2 className="select-none text-[1.1rem] font-medium">Verificación de Pago (Krece)</h2>

								<Select
									options={banks.map((bank) => ({ label: bank, value: bank }))}
									onSelectedChange={(value) =>
										setSelectedPayment({
											...selectedPayment,
											bank_id: value,
											...(value?.trim() === 'Banco Popular' ? {
												bank_account_number: '843641127'
											} : {
												bank_account_number: undefined
											})
										})
									}
									placeholder="Seleccionar banco"
								/>
								<Input
									allowedChars="numbers_only"
									placeholder="Número de cuenta"
									value={selectedPayment?.bank_account_number || ''}
									onChange={(value) => setSelectedPayment({ ...selectedPayment, bank_account_number: value })}
									validation={(value) => validate_bank_account_number(value, userData?.country)}
								/>
								<Input
									placeholder="Referencia"
									value={selectedPayment?.reference || ''}
									onChange={(value) => setSelectedPayment({ ...selectedPayment, reference: value })}
								/>
							</div>

							{error && typeof error === 'string' && <Notice message={error || 'Ocurrió un error'} variant="error" />}

							<div className="flex max-w-full justify-end gap-2">
								<Button2
									variant="default"
									className="shrink-0 bg-neutral-900 hover:bg-neutral-900/70"
									onClick={() => setSelectedPayment(null)}
								>
									Cerrar
								</Button2>
								<div className="w-full"></div>

								<Button2
									loading={loading}
									type="submit"
									variant="default"
									className={'shrink-0 bg-red-600 hover:bg-red-800'}
									onClick={() => {
										setPayload({ unverified_payment_id: selectedPayment.unverified_payment_id, accept_payment: false });
										setSubmitForm(true);
									}}
								>
									No pude verificarlo
								</Button2>

								<Button2
									loading={loading}
									disabled={!formValid || loading}
									type="submit"
									variant="default"
									className={error ? 'shrink-0 bg-yellow-500/70 hover:bg-yellow-800' : 'shrink-0 bg-sky-600 hover:bg-sky-800'}
									onClick={() => {
										setPayload({ ...selectedPayment });
										setSubmitForm(true);
									}}
								>
									{error ? 'Reintentar' : 'Verificar'}
								</Button2>
							</div>
						</form>
					</Dialog>
				)}
				<div className="space-y-4">
					<div className="space-y-0">
						<h2 className="text-2xl font-semibold text-neutral-100">Verificar Pagos</h2>
						<p className="text-sm text-neutral-400">Haga click en 'Confirmar' para verificar el pago.</p>
					</div>
					<Table
						key={tableKey}
						actionButton={{
							label: 'Confirmar',
							onClick: (row) => {
								setSelectedPayment({
									unverified_payment_id: row['ID del pago'],
									amount: row['Monto'],
									date: row['fecha'],
									customer_reference: row['Referencia'],
									customer_bank_account_number: row['Numero de cuenta']
								});
							}
						}}
						userEmail={userData?.master_store?.id}
						endpoint="https://api.krece.app/tables/unverified_payments/"
						pagination={{ default: 100, batchSizes: [100] }}
						orderBy={{ columnName: 'fecha', columnOrder: 'DESC' }}
						search_by={{ column: 'Numero de documento', placeholder: 'Número de documento', type: 'numeric' }}
						hideExport={true}
						className="rounded-lg border border-neutral-800 bg-neutral-900/50 p-4"
					/>
					<Table
						key={tableKey2}
						userEmail={userData?.master_store?.id}
						endpoint="https://api.krece.app/tables/bank_account_payments/"
						pagination={{ default: 100, batchSizes: [100] }}
						orderBy={{ columnName: 'fecha', columnOrder: 'DESC' }}
						search_by={{ column: 'Monto', placeholder: 'Monto', type: 'numeric' }}
						hideExport={true}
						className="rounded-lg border border-neutral-800 bg-neutral-900/50 p-4"
					/>
				</div>
			</div>
		);
	}
};

export default Internal;

/**
 * @typedef {Object} ClientData
 * @property {User} user
 * @property {Device[]} devices
 *
 * @typedef {Object} User
 * @property {string} centro
 * @property {boolean} welcome_message_sent
 * @property {string} photo_id_url
 * @property {string} zipcode
 * @property {string} first_name
 * @property {string} percent_down_approved
 * @property {number} max_loan_approved_3months
 * @property {string} estado
 * @property {string} direccion
 * @property {string} push_token
 * @property {string} identification_number
 * @property {number} available_loan_approved
 * @property {string} country
 * @property {EmploymentData} employment_data
 * @property {string} municipio
 * @property {string|null} store_user
 * @property {string|null} guarantor_name
 * @property {string} created_at
 * @property {string} identification_id_url
 * @property {FinancialData} financial_data
 * @property {number} interest_rate
 * @property {string} sex
 * @property {string|null} seller_store
 * @property {string[]} ontime_payments_ids
 * @property {string} registration_status
 * @property {string} phone_number
 * @property {string} level
 * @property {string} date_of_birth
 * @property {number} age
 * @property {string} user_registration_device_brand
 * @property {string|null} cne_identified
 * @property {string} parroquia
 * @property {number} available_loan_approved_krece_plus
 * @property {string} city
 * @property {string|null} marital_status
 * @property {string[]} devices_paid
 * @property {string} user_uuid
 * @property {number} max_loan_approved_6months
 * @property {number} max_loan_approved_6months_krece_plus
 * @property {string} referral_code
 * @property {string} education_level
 * @property {string} last_name
 * @property {boolean} is_test
 * @property {number} max_loan_approved_3months_krece_plus
 * @property {string} user_registration_device_model
 * @property {string[]} late_payments_ids
 * @property {boolean} is_active
 * @property {string|null} address
 * @property {string|null} client_type
 * @property {string|null} guarantor_phone_number
 * @property {string} email
 *
 * @typedef {Object} EmploymentData
 * @property {number} monthly_income_dollars
 * @property {string|null} current_position
 *
 * @typedef {Object} FinancialData
 * @property {string|null} house_owner
 * @property {string|null} simcard_payment_plan
 * @property {string|null} rent_payment
 * @property {string|null} bank_account
 * @property {number} savings
 *
 * @typedef {Object} Device
 * @property {string} operator
 * @property {string} product_description
 * @property {string} device_model
 * @property {string} sub_store
 * @property {string} created_at
 * @property {boolean} self_financed
 * @property {number} device_payments_left
 * @property {boolean} is_locked
 * @property {number} nuovopay_device_id
 * @property {string} user
 * @property {number} loan_payment_cycle
 * @property {string} country
 * @property {number} non_taxable_fees
 * @property {boolean} online_purchase
 * @property {number} total_interest
 * @property {any} date_paid_by_krece
 * @property {string} date_bought
 * @property {number} device_loan_duration
 * @property {number} payments_amount
 * @property {string} device_manufacturer
 * @property {boolean} device_paid_by_krece
 * @property {any} discount
 * @property {number} payments_amount_financed
 * @property {number} device_price
 * @property {boolean} is_test
 * @property {string} device_commercial_name
 * @property {string} anexo
 * @property {number} taxable_fees
 * @property {boolean} krece_lux
 * @property {string} seller_store
 * @property {string} device_imei_no
 * @property {string} device_type
 * @property {boolean} is_enrolled
 * @property {string} store_user
 * @property {boolean} new_chip_acquired_with_device
 * @property {string} device_management_provider
 * @property {boolean} is_released
 * @property {number} interest
 * @property {number} amount_financed
 * @property {boolean} store_financed
 * @property {string} phone_range
 * @property {number} payment_cycle_days
 * @property {number} device_confirmation_id
 * @property {string} device_serial_no
 * @property {boolean} is_active
 * @property {string} promotional_code
 * @property {string} id
 * @property {Payment[]} payments
 * @property {any[]} transactions
 *
 * @typedef {Object} Payment
 * @property {number} taxable_fees
 * @property {number} krece_fixed_fee
 * @property {boolean} paid
 * @property {number} interest
 * @property {boolean} paymentMethod
 * @property {number} amountPaid
 * @property {any} cobranza_owner
 * @property {string} country
 * @property {boolean} paid_to_store
 * @property {number} total_interest
 * @property {number} krece_percent_fee
 * @property {string} confirmationNumber
 * @property {string} created_at
 * @property {string} sub_store
 * @property {number} amount_owed_to_store
 * @property {boolean} is_active
 * @property {string} dueDate
 * @property {string} user
 * @property {boolean} screenshot
 * @property {boolean} store_financed
 * @property {number} actualAmount
 * @property {boolean} self_financed
 * @property {string} device
 * @property {number} non_taxable_fees
 * @property {string} related_store
 * @property {boolean} is_test
 * @property {boolean} checked_by_internal_team
 * @property {number} paymentPostponedTimes
 * @property {boolean} paymentPostponed
 * @property {string} id
 * @property {boolean} [second_reminder_sent]
 * @property {string} [last_reminder_date]
 * @property {number} [in_app_message_sent_count]
 */
