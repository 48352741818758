// src/context/GlobalContext.js
import React, { createContext, ReactNode, useState } from 'react';
import useAuth, { UserData } from '../hooks/auth';

const GlobalContext = createContext<{
	authenticated: boolean;
	userData: UserData | null;
	shouldLogin: boolean;
	internal: boolean;
	accessToOtherStores: any;
	newVersionAvailable: boolean;
	internalPath: { get: () => string; set: (path: string) => void };
}>({
	authenticated: false,
	userData: null,
	shouldLogin: false,
	internal: false,
	accessToOtherStores: null,
	newVersionAvailable: false,
	internalPath: { get: () => '', set: () => {} }
});

function GlobalProvider({ children }: { children: ReactNode }) {
	const { authenticated, userData, shouldLogin, internal, accessToOtherStores, newVersionAvailable } = useAuth();
	const [internalPath, setInternalPath] = useState('');

	return <GlobalContext.Provider value={{ authenticated, userData, shouldLogin, internal, accessToOtherStores, newVersionAvailable, internalPath: { get: () => internalPath, set: (path: string) => setInternalPath(path) } }}>{children}</GlobalContext.Provider>;
}

export { GlobalContext, GlobalProvider };
