import { useState, useEffect, useRef } from 'react';
import auth from '../auth';
import { onAuthStateChanged } from 'firebase/auth';
import firestore from '../firestore';

export interface UserData {
	firebase_token: string;
	email: string;
	limit_reached: boolean;
	flexible_installments: boolean;
	last_modified_date: {
		seconds: number;
		nanoseconds: number;
	};
	is_active: boolean;
	master_user: boolean;
	only_allow_tonic: boolean;
	krece_fixed_fee: number;
	allow_any_product: boolean;
	master_store: { id: string; [key: string]: any };
	'2fa_needed': boolean;
	store_financed: boolean;
	down_payment: number;
	security_provider: string;
	subStoreAdminPhoneNumbers: { [key: string]: string };
	krece_fee: number;
	self_financed: boolean;
	sub_stores: string[];
	principal_store: string;
	allow_online: boolean;
	store_name: string;
	zelleAccounts: string[];
	financed_by_krece: boolean;
	cobranza_access: boolean;
	country: 'venezuela' | string;
	registration_steps?: {
		terms_accepted?: boolean;
		company_info?: boolean;
		bank_created?: boolean;
		sucursal_created?: boolean;
		docusign_accepted?: boolean;
		registration_completed?: boolean;
	};
	access_to_other_stores?: boolean;
	access_type?: 'ata' | 'atc' | 'admin' | 'ob';
    bank_account?: {
        account_name?: string;
        account_number?: string;
        account_type?: string;
        bank_name?: string;
        document_type?: string;
        identification_number?: string;
        interbank_code?: string;
    }
}

const areRegistrationStepsEqual = (
	steps1: UserData['registration_steps'] | null,
	steps2: UserData['registration_steps'] | null
): boolean => {
	if (!steps1 || !steps2) return steps1 === steps2;

	const keys = Object.keys(steps1) as Array<keyof typeof steps1>;
	return keys.every((key) => steps1[key] === steps2[key]);
};

export default function useAuth() {
	const [authenticated, setAuthenticated] = useState(false);
	const [shouldLogin, setShouldLogin] = useState(false);
	const [userData, setUserData] = useState<UserData | null>(null);
	const [internal, setInternal] = useState(false);
	const [accessToOtherStores, setAccessToOtherStores] = useState<any>(null);
	const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);
	const registrationStepsRef = useRef<UserData['registration_steps'] | null>(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			try {
				if (!user?.email) throw new Error();

				const data = (await firestore.collection('Stores').doc(user.email).get())?.data() as
					| undefined
					| Omit<UserData, 'email' | 'firebase_token'>;
				if (!data) throw new Error();

				setShouldLogin(false);
				setAuthenticated(true);
				const firebase_token = await user.getIdToken();
				setUserData({
					firebase_token,
					email: user.email,
					...data
				} satisfies UserData);
				setInternal(data?.cobranza_access || ['ata', 'atc', 'admin'].includes(data?.access_type || ''));
				if (data?.access_to_other_stores) setAccessToOtherStores({token: firebase_token, email: user.email});
				if (data.registration_steps) registrationStepsRef.current = data.registration_steps;
			} catch (error) {
				setShouldLogin(true);
				setAuthenticated(false);
				setUserData(null);
				setInternal(false);
			}
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (!userData?.email || !registrationStepsRef.current) return;

		const unsubscribeStore = firestore
			.collection('Stores')
			.doc(userData.email)
			.onSnapshot(async (doc) => {
				try {
					const data = doc.data() as undefined | Omit<UserData, 'email' | 'firebase_token'>;
					if (!data?.registration_steps) throw new Error();

					if (!areRegistrationStepsEqual(registrationStepsRef.current, data.registration_steps)) {
						setUserData({
							...userData,
							registration_steps: data.registration_steps
						} satisfies UserData);
						registrationStepsRef.current = data.registration_steps;
					}
				} catch (error) {}
			});

		return () => unsubscribeStore();
	}, [userData]);

	let app_version: number | null | undefined = undefined;
	useEffect(() => {
		(async () => {
			app_version = (await firestore.collection('AppSetting').doc('AppSettings').get())?.data()?.app_version;
		})();
	}, []);

	useEffect(() => {
		const unsubscribeStore = firestore
			.collection('AppSetting')
			.doc('AppSettings')
			.onSnapshot(async (doc) => {
				try {
					const version = doc.data()?.app_version;
					if (typeof version === 'number' && typeof app_version === 'number' && version > app_version) setNewVersionAvailable(true);
				} catch (error) {}
			});

		return () => unsubscribeStore();
	}, []);

	return { authenticated, userData, shouldLogin, internal, accessToOtherStores, newVersionAvailable };
}

export function logout(callback?: () => void) {
	auth.signOut().catch(() => {
		window.location.href = '/logout';
	}).finally(() => {
		if (callback) callback();
	});
}
