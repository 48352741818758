import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { ChevronRight, TrendingDown, TrendingUp } from 'lucide-react';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ContainerSpinner } from '../components/Spinner';
import Table from '../components/Table';
import { GlobalContext } from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import useFetch from '../hooks/api';
import firestore from '../firestore';

function currency(amount: number, currencySymbol: string) {
	return currencySymbol + amount.toLocaleString('en-US', {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2
	});
}

function GetCountrySettings(country: string) {
	const [settings, setSettings] = useState<any>({});

	useEffect(() => {
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();

			if (document?.[country]) setSettings(document?.[country]);
		})();
	}, []);

	return settings;
}

export default function Inicio() {
	const { userData } = useContext(GlobalContext);
	const { master_store, store_name, master_user, cobranza_access, country } = userData!;
	const navigate = useNavigate();

	const { data, loading } = useFetch('https://api.krece.app/tables/storesummarydata/', 'POST', true, {
		master_store_email: master_store.id,
		...(master_user === false && { filter_by: [{ column_name: 'sub_store', value: store_name }] })
	});

	const [updateRegistrationSteps, setUpdateRegistrationSteps] = useState(false);
	const { setPayload: setUpdateRegistrationStepsPayload, error: updateRegistrationStepsError } = useFetch('https://api.krece.app/storeprofile/update_registration_steps/', 'POST', updateRegistrationSteps, undefined);

	const countrySettings = GetCountrySettings(country);
	const currencySymbol = countrySettings?.currency_code || '$';

	if (cobranza_access) {
		navigate('/internal');
		return null;
	}

	const AnalyticsTabs = () => (
		<Tabs.Root className="my-10" defaultValue="hoy">
			<Tabs.List className="mb-4 grid grid-cols-2 items-center gap-2 md:flex">
				<Tabs.Trigger
					className="w-full rounded-full bg-neutral-900 light:bg-neutral-925 p-2 text-sm text-neutral-300 data-[state=active]:!bg-primary-500/20 data-[state=active]:font-medium data-[state=active]:text-primary-800 dark:data-[state=active]:text-primary-100 md:w-28 md:text-base 3xl:text-lg"
					value="hoy"
				>
					Hoy
				</Tabs.Trigger>
				<Tabs.Trigger
					className="w-full rounded-full bg-neutral-900 light:bg-neutral-925 p-2 text-sm text-neutral-300 data-[state=active]:!bg-primary-500/20 data-[state=active]:font-medium data-[state=active]:text-primary-800 dark:data-[state=active]:text-primary-100 md:w-28 md:text-base 3xl:text-lg"
					value="ayer"
				>
					Ayer
				</Tabs.Trigger>
				<Tabs.Trigger
					className="w-full rounded-full bg-neutral-900 light:bg-neutral-925 p-2 text-sm text-neutral-300 data-[state=active]:!bg-primary-500/20 data-[state=active]:font-medium data-[state=active]:text-primary-800 dark:data-[state=active]:text-primary-100 dark:data md:w-40 md:text-base 3xl:text-lg"
					value="semana-actual"
				>
					Semana Actual
				</Tabs.Trigger>
				<Tabs.Trigger
					className="w-full rounded-full bg-neutral-900 light:bg-neutral-925 p-2 text-sm text-neutral-300 data-[state=active]:!bg-primary-500/20 data-[state=active]:font-medium data-[state=active]:text-primary-800 dark:data-[state=active]:text-primary-100 dark:data md:w-40 md:text-base 3xl:text-lg"
					value="semana-anterior"
				>
					Semana Anterior
				</Tabs.Trigger>
			</Tabs.List>
			<Tabs.Content value="hoy" className="data-[state=active]:animate-pop">
				<section className="no-scrollbar flex select-none gap-4 overflow-x-auto">
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Ventas</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{data?.find((e) => e.column_name === 'hoy')?.ventas || 0}
							</em>
							{data?.find((e) => e.column_name === 'hoy')?.percent_diff_ventas ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'hoy')!.percent_diff_ventas < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'hoy')!.percent_diff_ventas > 0
									})}
								>
									{data?.find((e) => e.column_name === 'hoy')!.percent_diff_ventas > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'hoy')!.percent_diff_ventas)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Monto Financiado</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'hoy')?.monto_financiado || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'hoy')?.percent_diff_monto_financiado ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'hoy')!.percent_diff_monto_financiado < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'hoy')!.percent_diff_monto_financiado > 0
									})}
								>
									{data?.find((e) => e.column_name === 'hoy')!.percent_diff_monto_financiado > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'hoy')!.percent_diff_monto_financiado)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Pagos en Tienda</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'hoy')?.pagos_en_tienda || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'hoy')?.percent_diff_pagos_en_tienda ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'hoy')!.percent_diff_pagos_en_tienda < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'hoy')!.percent_diff_pagos_en_tienda > 0
									})}
								>
									{data?.find((e) => e.column_name === 'hoy')!.percent_diff_pagos_en_tienda > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'hoy')!.percent_diff_pagos_en_tienda)}%</span>
								</div>
							) : null}
						</div>
					</article>
				</section>
			</Tabs.Content>
			<Tabs.Content value="ayer" className="data-[state=active]:animate-pop">
				<section className="no-scrollbar flex select-none gap-4 overflow-x-auto">
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Ventas</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{data?.find((e) => e.column_name === 'ayer')?.ventas || 0}
							</em>
							{data?.find((e) => e.column_name === 'ayer')?.percent_diff_ventas ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'ayer')!.percent_diff_ventas < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'ayer')!.percent_diff_ventas > 0
									})}
								>
									{data?.find((e) => e.column_name === 'ayer')!.percent_diff_ventas > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'ayer')!.percent_diff_ventas)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Monto Financiado</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'ayer')?.monto_financiado || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'ayer')?.percent_diff_monto_financiado ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'ayer')!.percent_diff_monto_financiado < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'ayer')!.percent_diff_monto_financiado > 0
									})}
								>
									{data?.find((e) => e.column_name === 'ayer')!.percent_diff_monto_financiado > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'ayer')!.percent_diff_monto_financiado)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Pagos en Tienda</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'ayer')?.pagos_en_tienda || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'ayer')?.percent_diff_pagos_en_tienda ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'ayer')!.percent_diff_pagos_en_tienda < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'ayer')!.percent_diff_pagos_en_tienda > 0
									})}
								>
									{data?.find((e) => e.column_name === 'ayer')!.percent_diff_pagos_en_tienda > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'ayer')!.percent_diff_pagos_en_tienda)}%</span>
								</div>
							) : null}
						</div>
					</article>
				</section>
			</Tabs.Content>
			<Tabs.Content value="semana-actual" className="data-[state=active]:animate-pop">
				<section className="no-scrollbar flex select-none gap-4 overflow-x-auto">
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Ventas</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{data?.find((e) => e.column_name === 'semana actual')?.ventas || 0}
							</em>
							{data?.find((e) => e.column_name === 'semana actual')?.percent_diff_ventas ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'semana actual')!.percent_diff_ventas < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'semana actual')!.percent_diff_ventas > 0
									})}
								>
									{data?.find((e) => e.column_name === 'semana actual')!.percent_diff_ventas > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'semana actual')!.percent_diff_ventas)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Monto Financiado</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'semana actual')?.monto_financiado || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'semana actual')?.percent_diff_monto_financiado ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'semana actual')!.percent_diff_monto_financiado < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'semana actual')!.percent_diff_monto_financiado > 0
									})}
								>
									{data?.find((e) => e.column_name === 'semana actual')!.percent_diff_monto_financiado > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'semana actual')!.percent_diff_monto_financiado)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Pagos en Tienda</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'semana actual')?.pagos_en_tienda || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'semana actual')?.percent_diff_pagos_en_tienda ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'semana actual')!.percent_diff_pagos_en_tienda < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'semana actual')!.percent_diff_pagos_en_tienda > 0
									})}
								>
									{data?.find((e) => e.column_name === 'semana actual')!.percent_diff_pagos_en_tienda > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'semana actual')!.percent_diff_pagos_en_tienda)}%</span>
								</div>
							) : null}
						</div>
					</article>
				</section>
			</Tabs.Content>
			<Tabs.Content value="semana-anterior" className="data-[state=active]:animate-pop">
				<section className="no-scrollbar flex select-none gap-4 overflow-x-auto">
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Ventas</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{data?.find((e) => e.column_name === 'semana anterior')?.ventas || 0}
							</em>
							{data?.find((e) => e.column_name === 'semana anterior')?.percent_diff_ventas ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_ventas < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_ventas > 0
									})}
								>
									{data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_ventas > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_ventas)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Monto Financiado</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'semana anterior')?.monto_financiado || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'semana anterior')?.percent_diff_monto_financiado ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_monto_financiado < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_monto_financiado > 0
									})}
								>
									{data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_monto_financiado > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_monto_financiado)}%</span>
								</div>
							) : null}
						</div>
					</article>
					<article className="min-w-[40%] max-w-[500px] shrink-0 space-y-8 rounded-lg border-[1.5px] border-neutral-900 bg-neutral-925 light:bg-foreground p-4 lg:w-full lg:min-w-0 lg:shrink 3xl:p-6">
						<header className="text-sm text-neutral-300 2xl:text-base 3xl:text-lg">Pagos en Tienda</header>
						<div className="flex items-center justify-between gap-4">
							<em className="text-lg font-bold not-italic tracking-tight 3xl:text-xl">
								{currency(data?.find((e) => e.column_name === 'semana anterior')?.pagos_en_tienda || 0, currencySymbol)}
							</em>
							{data?.find((e) => e.column_name === 'semana anterior')?.percent_diff_pagos_en_tienda ? (
								<div
									className={classNames('flex items-center justify-center gap-1 rounded-lg p-1 text-xs text-white 3xl:text-sm', {
										'bg-red-500/85': data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_pagos_en_tienda < 0,
										'bg-green-500': data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_pagos_en_tienda > 0
									})}
								>
									{data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_pagos_en_tienda > 0 ? (
										<TrendingUp strokeWidth={1.75} className="size-3" />
									) : (
										<TrendingDown strokeWidth={1.75} className="size-3" />
									)}
									<span>{Math.abs(data?.find((e) => e.column_name === 'semana anterior')!.percent_diff_pagos_en_tienda)}%</span>
								</div>
							) : null}
						</div>
					</article>
				</section>
			</Tabs.Content>
		</Tabs.Root>
	);

	const SummaryTables = () => (
		<div className="grid grid-cols-1 gap-4 light:gap-8">
			<section className="w-full space-y-4">
				<Link to="/reportes/ventas">
					<header className="group flex cursor-pointer items-center justify-between">
						<h2 className="text-xl font-medium tracking-tight transition-colors group-hover:text-primary-400 3xl:text-2xl">
							Ventas
						</h2>
						<ChevronRight
							strokeWidth={2.25}
							className="size-6 text-slate-200 transition-all group-hover:translate-x-[2px] group-hover:text-primary-400 3xl:size-8"
						/>
					</header>
				</Link>
				<Table
					userEmail={master_store.id}
					endpoint="https://api.krece.app/tables/devices/"
					pagination={{ default: 10, batchSizes: [10] }}
					orderBy={{ columnName: 'Fecha de Compra', columnOrder: 'DESC' }}
					hideDateFilters={true}
					hideExport={true}
					hidePagination={true}
					className="!h-[400px]"
				/>
			</section>
			<section className="w-full space-y-4">
				<Link to="/reportes/transacciones">
					<header className="group flex cursor-pointer items-center justify-between">
						<h2 className="text-xl font-medium tracking-tight transition-colors group-hover:text-primary-400 3xl:text-2xl">
							Transacciones
						</h2>
						<ChevronRight
							strokeWidth={2.25}
							className="size-6 text-slate-200 transition-all group-hover:translate-x-[2px] group-hover:text-primary-400 3xl:size-8"
						/>
					</header>
				</Link>
				<Table
					userEmail={master_store.id}
					endpoint="https://api.krece.app/tables/transactions/"
					pagination={{ default: 10, batchSizes: [10] }}
					orderBy={{ columnName: 'Fecha Reportado', columnOrder: 'DESC' }}
					hideDateFilters={true}
					hideExport={true}
					hidePagination={true}
					className="!h-[400px]"
				/>
			</section>
		</div>
	);

	return (
		<>
			{!data && <ContainerSpinner size={3} />}
			{data && (
				<div className="p-4 md:p-[1.25rem] relative">
					<div className="flex select-none flex-col gap-1 3xl:gap-2">
						<p className="text-lg text-neutral-300 3xl:text-xl">¡Bienvenido! 👋</p>
						<strong className="text-2xl text-neutral-0 3xl:text-3xl">{store_name}</strong>
					</div>
					{!loading && (master_store.id === 'test@store.com' || master_store.id === 'testrd@store.com' || master_store.id === 'testperu@store.com') && (
						<button
							className="absolute top-0 right-6 mt-6 bg-primary-500/20 text-primary-400 px-4 py-2 rounded-lg"
							onClick={() => {
								setUpdateRegistrationStepsPayload({
									master_store_email: master_store.id,
									registration_steps: {
										company_info: false,
										bank_created: false,
										sucursal_created: false,
										registration_completed: false,
										terms_accepted: false
									}
								});
								setUpdateRegistrationSteps(true);
							}}
						>
							Probar Onboarding
						</button>
					)}
					<AnalyticsTabs />
					<SummaryTables />

					{/* Sidebar */}
					{/* <div className="hidden h-full w-3/12 max-w-[500px] shrink-0 flex-col gap-4 bg-neutral-900 p-6 lg:flex">
							<div className="h-32 rounded-xl bg-foreground"></div>
							<div className="h-32 rounded-xl bg-foreground"></div>
							<div className="h-32 rounded-xl bg-foreground"></div>
							<div className="h-32 rounded-xl bg-foreground"></div>
							<div className="h-32 rounded-xl bg-foreground"></div>
							<div className="h-32 rounded-xl bg-foreground"></div>
					</div> */}
				</div>
			)}
		</>
	);
}
